import ColorPickerPopover from "~/components/atoms/ColorPickerPopover";
import { usePuckContext } from "~/utilities/config/puckConfig";
export interface ColorPickerItem {
  label?: string;
  name: string;
  defaultValue: string;
}

export interface ColorPickerGroupProps {
  group: ColorPickerItem[];
  onChange: (value: Record<string, string>) => void; // colorName: colorValue
  value: Record<string, string>;
}

export default function ColorPickerGroup({
  group,
  value,
  onChange,
}: ColorPickerGroupProps) {
  const { colorVariables } = usePuckContext();

  return (
    <div
      style={{
        ...colorVariables?.root,
        // @ts-expect-error Not check variable section variable
        "--color-color": colorVariables?.root?.["--color-dark"],
      }}
      className="grid grid-cols-2 gap-x-2 gap-y-4"
    >
      {group.map((item) => {
        return (
          <div key={item.name} className="w-full">
            <ColorPickerPopover
              label={item.label}
              value={value?.[item.name] || item?.defaultValue}
              onChange={(color) => {
                onChange({
                  ...value,
                  [item.name]: color,
                });
              }}
              name={item.name}
            />
          </div>
        );
      })}
    </div>
  );
}
