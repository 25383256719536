import LDPSection, { LDPSectionProps } from "../../LDPSection";
import LDPVideo from "../../LDPVideo";

import CleanedContent from "~/components/atoms/CleanedContent";
import { Icon } from "~/components/atoms/Icons";
import Image from "~/components/atoms/Image";
import { IMediaImageUrls, IMediaVideoUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O2,
} from "~/utilities/config/landingPageConfig";
import { EIcon } from "~/utilities/enums/Icons";
import { isLocalImage } from "~/utilities/helpers/isUrl";

export interface LDPProjectVideoProps extends LDPSectionProps {
  title: string;
  descriptionHtml: string;
  videoMedia?: IMediaVideoUrls;
  thumbnail?: IMediaImageUrls;
  partnerLogo?: string;
}

export default function LDPProjectVideo({
  title,
  descriptionHtml,
  styleRoot = VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPProjectVideo],
  thumbnail,
  videoMedia,
  partnerLogo,
  className,
  ...rest
}: LDPProjectVideoProps) {
  return (
    <LDPSection
      className={cn(
        "py-24 lg:py-12 [&_.ldp-video]:rounded-tl-[160px] [&_.ldp-video]:lg:rounded-tl-[75px]",
        className
      )}
      styleRoot={styleRoot}
      {...rest}
    >
      <div className="grid grid-cols-5 gap-6 lg:flex lg:grid-cols-1 lg:flex-col-reverse">
        <div className="col-span-2 mr-20 flex flex-1 flex-col gap-8 lg:col-span-full  lg:mr-0 lg:px-4">
          <CleanedContent
            className="ldp-video-title h-auto !text-header-56 text-[var(--main-color)] lg:!text-header-32"
            htmlContent={title}
          />

          <hr className="h-0.5 bg-[var(--main-color)] text-[var(--main-color)]" />

          <div className="flex-1">
            <Icon
              className="mb-6 max-w-max [&_path]:fill-[var(--main-color)]"
              TypeIcon={EIcon.QuoteIcon}
            />

            <CleanedContent
              className="h-auto text-[var(--main-color)] opacity-75"
              htmlContent={descriptionHtml}
            />
          </div>

          {partnerLogo && (
            <Image
              isLocalImage={isLocalImage(partnerLogo)}
              url={partnerLogo}
              alt="parnerLogo"
              className="h-auto w-full max-w-[180px]"
            />
          )}
        </div>

        <div className="ldp-video-container col-span-3 lg:col-span-full">
          <LDPVideo
            className="h-[457px] overflow-hidden lg:h-auto"
            videoMedia={videoMedia}
            thumbnail={thumbnail}
          />
        </div>
      </div>
    </LDPSection>
  );
}
