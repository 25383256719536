import { ReactNode, useMemo, useRef, useState } from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import LDPSection, { LDPSectionProps } from "../../LDPSection";

import CleanedContent from "~/components/atoms/CleanedContent";
import { Icon } from "~/components/atoms/Icons";
import Media from "~/components/atoms/Media";
import ModalMediaGallery from "~/components/organisms/ModalMediaGallery";
import { TMedia } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import { EIcon } from "~/utilities/enums/Icons";

export interface LDPGalleryProps extends LDPSectionProps {
  title?: string;
  description?: string;
  project: IProject;
  medias?: TMedia[];
  collapseOptions?: {
    viewMoreText?: string;
    viewLessText?: string;
    enable?: boolean;
    icon?: ReactNode;
  };
}

export default function LDPGallery({
  id,
  title = "Gallery",
  description,
  project,
  medias,
  collapseOptions = {
    viewLessText: "View less",
    viewMoreText: "View more",
    enable: true,
  },
  styleRoot,
}: LDPGalleryProps) {
  const [galley, setGallery] = useState({
    isOpen: false,
    currenIndex: 0,
  });

  const [currentSlide, setCurrentSlide] = useState(1); // Tracks the active slide

  const swiperRef = useRef<any>(null);

  const handleOpen = (idx: number) => {
    setGallery({
      currenIndex: idx,
      isOpen: true,
    });
  };

  const handleClose = () => {
    setGallery({
      currenIndex: 0,
      isOpen: false,
    });
  };

  const galleryMedias = useMemo(() => {
    if (medias && medias?.length > 0) {
      return medias;
    }

    return project?.medias;
  }, [project.medias, medias]);

  return (
    <>
      <LDPSection
        styleRoot={{
          ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPGallery],
          ...styleRoot,
        }}
        classNameSection="py-24 relative  overflow-hidden"
        id={id}
      >
        <div className="circle absolute bottom-0 right-0 max-w-[1237px]  translate-x-1/3  translate-y-[40%]   bg-[var(--circle-2)]  lg:max-w-[450px] lg:translate-y-1/4" />
        <div className="relative z-10 flex flex-col  items-center gap-y-12 overflow-visible lg:gap-y-8">
          <div className=" relative  z-10 flex w-full flex-col items-center gap-y-8 overflow-visible [&_*]:!text-[var(--color-text)]">
            <div className="circle absolute -bottom-1 left-[200px] z-[1] max-w-[217px] bg-[--circle-1]  lg:left-4 lg:max-w-[76px]  lg:-translate-y-1/2 lg:translate-x-0" />

            <CleanedContent
              htmlContent={title}
              className="z-10 !text-header-56 font-bold text-color lg:!text-header-32"
            />

            {description && (
              <CleanedContent
                htmlContent={description}
                className="z-10 text-body-title-16 text-color"
              />
            )}
          </div>
          <div className="ldp-gallery relative h-full  w-full gap-6 overflow-visible lg:gap-4">
            <Swiper
              slidesPerView={1}
              modules={[Autoplay]}
              autoplay={{
                delay: 5000,
              }}
              spaceBetween={16}
              className="overflow-visible"
              breakpoints={{
                768: {
                  centeredSlides: true,
                  centeredSlidesBounds: true,
                  spaceBetween: 24,
                  slidesPerView: "auto",
                },
              }}
              ref={swiperRef}
              onSlideChange={(swiper) =>
                setCurrentSlide(swiper.activeIndex + 1)
              }
            >
              {galleryMedias.map((item, idx) => {
                return (
                  <SwiperSlide
                    className={cn(
                      "h-auto w-full max-w-[824px] lg:max-w-[624px] md:max-w-full"
                    )}
                    key={item.id}
                  >
                    <button className="w-full" onClick={() => handleOpen(idx)}>
                      <Media
                        pictureClassName="block"
                        className="h-[458px] w-full rounded-3xl object-cover object-center lg:h-[400px] lg:rounded-2xl"
                        media={item}
                      />
                    </button>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            {!!project?.medias?.length && (
              <>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    swiperRef.current?.swiper?.slidePrev();
                  }}
                  className={cn(
                    "absolute left-0 top-1/2  z-50   h-10 w-10   -translate-x-1/3 -translate-y-1/2 items-center justify-center rounded-full bg-[#CBE8D2] shadow-md transition-transform duration-200",
                    {
                      "cursor-not-allowed opacity-50": currentSlide - 1 === 0,
                      "hover:scale-105 active:scale-95": currentSlide - 1 !== 0,
                    }
                  )}
                  disabled={currentSlide - 1 === 0}
                >
                  <Icon
                    TypeIcon={EIcon.ArrowLeftIcon}
                    height={40}
                    width={40}
                    className="!text-black"
                  />
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    swiperRef.current?.swiper?.slideNext();
                  }}
                  className={cn(
                    "absolute right-0 top-1/2 z-50    flex  h-10   w-10 -translate-y-1/2   translate-x-1/3 items-center justify-center   rounded-full bg-[#0F393A] shadow-md transition-transform duration-200 ",
                    {
                      "cursor-not-allowed opacity-50":
                        currentSlide - 1 === galleryMedias.length - 1,
                      "hover:scale-105 active:scale-95":
                        currentSlide - 1 !== galleryMedias.length - 1,
                    }
                  )}
                  disabled={currentSlide - 1 === galleryMedias.length - 1}
                >
                  <Icon
                    TypeIcon={EIcon.ArrowRightIcon}
                    height={40}
                    width={40}
                    className="!text-white"
                    style={{
                      color: "white !important",
                    }}
                  />
                </button>
              </>
            )}
          </div>
        </div>
      </LDPSection>

      {galley.isOpen && (
        <ModalMediaGallery
          medias={project?.medias}
          closeModal={handleClose}
          isOpen={galley.isOpen}
          indexSlide={galley.currenIndex}
        />
      )}
    </>
  );
}
