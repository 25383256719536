import { useState } from "react";

import Button from "~/components/atoms/Button";
import SelectedMedia from "~/components/atoms/UploadZone/SelectedMedia.client";
import Uploader, { UploaderProps } from "~/components/molecules/Uploader";
import { TMedia } from "~/entities/media";
import { useModal } from "~/hooks/use-modal";

export interface UploaderModalProps
  extends Omit<UploaderProps, "onSelectChange"> {
  onChange: (d?: TMedia[]) => void;
  value?: TMedia[];
  modalTitle?: string;
}

export default function UploaderModal({
  onChange,
  value,
  projectMedias,
  maxSelect, // maxSelect  = 1 mean single pick
  modalTitle,
  ...props
}: UploaderModalProps) {
  const [data, setData] = useState<TMedia[]>(value || []);

  const { modal, openModal, closeModal } = useModal({
    children: (
      <Uploader
        projectMedias={projectMedias}
        onSelectChange={setData}
        defaultSelected={data}
        maxSelect={maxSelect}
        {...props}
      />
    ),
    allowBackdropClick: false,
    hasWrapper: true,
    className: "max-w-[80%] max-h-[85svh]  scroll-hidden",
    title: modalTitle || "Upload File",
    hasActionBottom: true,
    onClose: () => {
      setData(value || []);
    },
    actionsModal: {
      bottom: {
        submit: () => {
          onChange(data.length > 0 ? data : undefined);
          closeModal?.();
        },
      },
    },
  });

  return (
    <div>
      <div className="flex flex-col gap-y-2">
        <Button className="w-full" onClick={openModal} type="button">
          {value?.length ? "Edit" : "Upload"}
        </Button>

        {value && value.length > 0 && (
          <div className="sticky right-0 top-24">
            <SelectedMedia
              selectedMedia={value}
              onReSort={onChange}
              className="[&_.listWrapper]:max-h-[200px]"
            />
          </div>
        )}
      </div>

      {modal}
    </div>
  );
}
