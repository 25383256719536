import { Icon } from "~/components/atoms/Icons";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SaleActionsBarProps {
  salePhoneNumber: string;
  saleContent: string;
  onRegisterClick?: () => void;
  className?: string;
  iconSize?: number;
  theme?: "light" | "dark";
}

export default function SaleActionsBar(props: SaleActionsBarProps) {
  const {
    salePhoneNumber,
    saleContent,
    onRegisterClick,
    className,
    iconSize = 30,
    theme = "light",
  } = props;

  return (
    <div
      className={cn(
        "flex w-full items-center justify-between gap-3",
        theme === "light" ? "text-color" : "text-white",
        className
      )}
    >
      <div className="actionItem flex flex-1 items-center justify-center">
        <a
          href={`https://api.whatsapp.com/send?phone=${salePhoneNumber.replace(
            /\D/g,
            ""
          )}&text=${encodeURIComponent(saleContent)}`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex w-full flex-col items-center justify-center gap-2  transition-all hover:scale-105 active:scale-95">
            <div className="flex items-center justify-center">
              <Icon
                TypeIcon={EIcon.WhatsAppContactIcon}
                width={iconSize}
                height={iconSize}
                className={cn(
                  theme === "dark" &&
                    "[&_path[data-fill='background']]:fill-white [&_path]:fill-footerBackground [&_rect]:fill-white"
                )}
              />
            </div>

            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_12}
              className="truncate font-semibold"
              color={
                theme === "light" ? ETypoColor.GABLE_GREEN : ETypoColor.WHITE
              }
            >
              CHAT
            </Typo>
          </div>
        </a>
      </div>

      <div className="actionItem flex flex-1 items-center justify-center">
        <a href={`tel:${salePhoneNumber}`}>
          <div className=" flex w-full flex-col items-center justify-center gap-2  transition-all hover:scale-105 active:scale-95">
            <div className="flex items-center justify-center">
              <Icon
                TypeIcon={EIcon.PhoneIcon}
                width={iconSize}
                height={iconSize}
                className={cn(
                  theme === "light" && "[&_path]:fill-color",
                  theme === "dark" && "[&_path]:fill-white"
                )}
              />
            </div>
            <Typo
              tag={ETypoTag.SPAN}
              variant={ETypoVariant.BODY_TITLE_12}
              className="truncate font-semibold"
              color={
                theme === "light" ? ETypoColor.GABLE_GREEN : ETypoColor.WHITE
              }
            >
              SHOWFLAT NUMBER
            </Typo>
          </div>
        </a>
      </div>

      {onRegisterClick && (
        <div className="actionItem flex flex-1 items-center justify-center">
          <button onClick={onRegisterClick} className="w-fit">
            <div className="flex w-full flex-col items-center justify-center gap-2  transition-all hover:scale-105 active:scale-95">
              <div className="flex items-center justify-center">
                <Icon
                  TypeIcon={EIcon.RegisterIcon}
                  width={iconSize}
                  height={iconSize}
                  className={cn(
                    theme === "light" && "[&_path]:fill-color",
                    theme === "dark" && "[&_path]:fill-white"
                  )}
                />
              </div>
              <Typo
                tag={ETypoTag.SPAN}
                variant={ETypoVariant.BODY_TITLE_12}
                className="truncate font-semibold"
                color={
                  theme === "light" ? ETypoColor.GABLE_GREEN : ETypoColor.WHITE
                }
              >
                REGISTER
              </Typo>
            </div>
          </button>
        </div>
      )}
    </div>
  );
}
