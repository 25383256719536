import { isEmpty, unionBy } from "lodash";
import * as React from "react";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";

import Button from "~/components/atoms/Button";
import FileUploader, {
  FileUploaderProps,
} from "~/components/atoms/UploadZone/FileUploader.client";
import PreviewMedia from "~/components/atoms/UploadZone/PreviewMedia.client";
import SelectedMedia from "~/components/atoms/UploadZone/SelectedMedia.client";
import { IMediaImageUrls, IMediaVideoUrls, TMedia } from "~/entities/media";
import { EMedia } from "~/utilities/enums/Media";

export interface UploaderProps extends Omit<FileUploaderProps, "onUpload"> {
  onSelectChange: (mediaSelected: TMedia[]) => void;
  projectMedias?: TMedia[] & { mediaUploaded?: boolean };
  maxSelect?: number; // If not defined, all media can be selected
  defaultSelected?: TMedia[];
  allowYoutube?: boolean;
}

export default function Uploader({
  onSelectChange,
  projectMedias = [],
  maxSelect,
  defaultSelected = [],
  // Upload Zone Props
  accept = {
    "image/*": ["*"],
    "video/*": ["*"],
  },
  DropZoneContent,
  multiple,
  dragAndDrop,
  allowYoutube = false,
}: UploaderProps) {
  const defaultMedias = [...projectMedias];

  const isMultipleSelect = maxSelect !== 1; // If not defined, all media can be selected

  const [medias, setMedias] = React.useState<
    TMedia[] & { mediaUploaded?: boolean }
  >(defaultMedias);

  const [selected, setSelected] = React.useState<TMedia[]>(defaultSelected);

  const [hoveringId, setHoveringId] = React.useState<string | null>(null);

  const [youtubeUrls, setYoutubeUrls] = React.useState<string>("");

  const { images, videos } = React.useMemo(() => {
    const images: (IMediaImageUrls & { mediaUploaded?: boolean })[] = [];
    const videos: (IMediaVideoUrls & { mediaUploaded?: boolean })[] = [];

    unionBy(medias, "id").forEach((media) => {
      switch (media?.type) {
        case EMedia.IMAGE:
          images.push(media);
          break;
        case EMedia.VIDEO:
          videos.push(media);
          break;
        default:
          break;
      }
    });
    return {
      images,
      videos,
    };
  }, [medias]);

  const allowSelect = React.useMemo(() => {
    const acceptImage =
      images.length > 0 &&
      Object.keys(accept).some((item) => item.includes("image"));
    const acceptVideo =
      videos.length > 0 &&
      Object.keys(accept).some((item) => item.includes("video"));

    return { acceptImage, acceptVideo };
  }, [accept, images, videos]);

  const handleUpdateMedia = (mediaUpdate: TMedia[]) => {
    if (maxSelect && mediaUpdate.length > maxSelect && isMultipleSelect) {
      toast.error(`Selection limit exceeded (max:${maxSelect})`);
      return;
    }

    setSelected(mediaUpdate);
    onSelectChange?.(mediaUpdate);
  };

  const handleSelect = (media: TMedia, isSelected: boolean) => {
    if (!isMultipleSelect) {
      handleUpdateMedia([media]);
      return;
    }

    const mediaUpdate = isSelected
      ? [...selected, media]
      : selected.filter((item) => item.id !== media.id);

    handleUpdateMedia(mediaUpdate);
  };

  // Function to validate YouTube URL
  const isValidYoutubeUrl = (url: string) => {
    try {
      const urlObj = new URL(url);

      // Kiểm tra nếu đây là domain YouTube
      if (
        urlObj.hostname === "youtu.be" ||
        urlObj.hostname.includes("youtube.com")
      ) {
        let videoId = null;

        if (urlObj.hostname === "youtu.be") {
          videoId = urlObj.pathname.slice(1);
        } else if (urlObj.pathname.includes("/watch")) {
          videoId = urlObj.searchParams.get("v");
        } else if (urlObj.pathname.includes("/embed/")) {
          videoId = urlObj.pathname.split("/embed/")[1];
        } else if (urlObj.pathname.includes("/v/")) {
          videoId = urlObj.pathname.split("/v/")[1];
        }

        return videoId && videoId.length > 8;
      }

      return false;
    } catch (_) {
      return false;
    }
  };

  // Function to add YouTube URL as media
  const handleAddYoutubeUrls = () => {
    if (!allowYoutube) return;
    if (!youtubeUrls.trim()) {
      toast.error("Please enter YouTube URLs");
      return;
    }

    // Split by new lines to get individual URLs
    const urls = youtubeUrls.split(/\r?\n/).filter((url) => url.trim() !== "");

    if (urls.length === 0) {
      toast.error("Please enter valid YouTube URLs");
      return;
    }

    let validCount = 0;
    let invalidCount = 0;

    // Process each URL
    const newMedias = urls
      .map((url) => {
        const trimmedUrl = url.trim();

        if (!isValidYoutubeUrl(trimmedUrl)) {
          invalidCount++;
          return null;
        }

        validCount++;
        return {
          id: uuidv4(),
          type: EMedia.VIDEO,
          urls: [trimmedUrl],
          mediaUploaded: true,
        } as IMediaVideoUrls;
      })
      .filter(Boolean) as IMediaVideoUrls[];

    if (newMedias.length > 0) {
      setMedias((prev) => [...newMedias, ...prev]);
      setYoutubeUrls("");

      if (invalidCount > 0) {
        toast.warning(
          `Added ${validCount} videos. ${invalidCount} invalid URLs were skipped.`
        );
      } else {
        toast.success(`Added ${validCount} YouTube videos successfully`);
      }
    } else {
      toast.error("No valid YouTube URLs found");
    }
  };

  return (
    <div className="grid grid-cols-3 grid-rows-3 gap-8  lg:grid-cols-1">
      <div className="col-span-2 row-span-full grid h-full gap-4 overflow-hidden lg:col-span-full">
        {!isEmpty(accept) && (
          <div className="col-span-full row-span-1 h-full">
            <h3 className="mb-4 font-semibold">Upload zone</h3>
            <FileUploader
              onUpload={(data) => {
                setMedias((prev) => [...data, ...prev]);
              }}
              accept={accept}
              DropZoneContent={DropZoneContent}
              dragAndDrop={dragAndDrop}
              multiple={multiple}
            />
          </div>
        )}

        {/* YouTube URL input section */}
        {allowYoutube && (
          <div className="col-span-full">
            <h3 className="mb-4 font-semibold">YouTube URLs</h3>
            <div className="flex flex-col gap-2">
              <textarea
                value={youtubeUrls}
                onChange={(e) => setYoutubeUrls(e.target.value)}
                placeholder="Paste YouTube URLs here (one URL per line)"
                className="w-full rounded-md border border-gray-300 bg-transparent px-3 py-2 focus:border-blue-500 focus:outline-none"
                rows={4}
              />
              <div className="flex items-center">
                <Button className="rounded-md" onClick={handleAddYoutubeUrls}>
                  Add Multiple Videos
                </Button>
                <span className="ml-2 text-xs text-gray-500">
                  Enter one YouTube URL per line
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="scroll col-span-full row-span-2 h-full  space-y-4 overflow-auto  lg:max-h-full ">
          {allowSelect.acceptImage && (
            <div className="relative flex flex-col gap-4">
              <h3 className="sticky left-0 top-0 z-20 bg-white p-2 font-semibold">
                Images
              </h3>
              <div className="grid grid-cols-3 gap-6">
                {images.map((item, idx) => (
                  <PreviewMedia
                    key={item.id}
                    media={item}
                    id={item.id}
                    isDefault={!item.mediaUploaded}
                    position={
                      selected.findIndex(
                        (selected) => selected.id === item.id
                      ) + 1
                    }
                    onSelect={handleSelect}
                    isMaxSelected={maxSelect === selected.length}
                    isMultiple={isMultipleSelect}
                    onHover={(id) => setHoveringId(id)}
                    onLeave={() => setHoveringId(null)}
                    hoveringId={hoveringId}
                  />
                ))}
              </div>
            </div>
          )}

          {(allowSelect.acceptVideo || allowYoutube) && (
            <div className="flex flex-col gap-4">
              <h3 className="sticky left-0 top-0 z-20 bg-white p-2 font-semibold">
                Videos
              </h3>
              <div className="grid grid-cols-3 gap-6">
                {videos.map((item, idx) => (
                  <PreviewMedia
                    media={item}
                    key={item.id}
                    id={item.id}
                    isDefault={!item.mediaUploaded}
                    position={
                      selected.findIndex(
                        (selected) => selected.id === item.id
                      ) + 1
                    }
                    isMaxSelected={maxSelect === selected.length}
                    isMultiple={isMultipleSelect}
                    onSelect={handleSelect}
                    onHover={(id) => setHoveringId(id)}
                    onLeave={() => setHoveringId(null)}
                    hoveringId={hoveringId}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="col-span-1 row-span-full lg:col-span-full lg:row-span-1">
        {selected.length > 0 && (
          <div className="sticky right-0 top-24">
            <SelectedMedia
              selectedMedia={selected}
              onHover={(id) => setHoveringId(id)}
              onLeave={() => setHoveringId(null)}
              hoveringId={hoveringId}
              onReSort={handleUpdateMedia}
            />
          </div>
        )}
      </div>
    </div>
  );
}
