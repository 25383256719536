import dayjs from "dayjs";
import { forwardRef, memo } from "react";
import { useTranslation } from "react-i18next";

import LDPSection, { LDPSectionProps } from "../../LDPSection";

import CleanedContent from "~/components/atoms/CleanedContent";
import Typo from "~/components/atoms/Typo";
import CardProjectDetail from "~/components/molecules/CardProjectDetail";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface LDPProjectDetailProps extends LDPSectionProps {
  className?: string;
  id: string;
  title: string;
  project: IProject;
  backgroundColor?: string;
}

function getRandomItem(array: any[]) {
  if (!array.length) {
    return [];
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export type Ref = HTMLDivElement;

const LDPProjectDetail = forwardRef<Ref, LDPProjectDetailProps>(
  ({ className = "", id, project, styleRoot }, ref) => {
    const { t } = useTranslation();
    if (!project) return null;

    return (
      <LDPSection
        ref={ref}
        id={id}
        className={cn("pj-detail mx-auto py-24 lg:py-12", className)}
        styleRoot={{
          ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPProjectDetail],
          ...styleRoot,
        }}
      >
        <div className="grid grid-cols-2 gap-6 lg:grid-cols-1 lg:gap-8">
          <div className="relative col-span-1 pb-0 ">
            <div className="flex flex-col gap-y-12 lg:gap-y-4">
              <Typo
                className="font-bold"
                tag={ETypoTag.H2}
                variant={ETypoVariant.HEADER_56}
              >
                {project?.name}
              </Typo>
              <CleanedContent
                htmlContent={project.description}
                className="h-full 
                 w-full  max-w-[506px] text-body-title-16 text-color"
              />
            </div>
          </div>

          <hr className="col-span-1 hidden h-px w-full bg-[var(--color-text)] lg:block " />

          <div className="col-span-1">
            <div className="flex w-full  flex-col gap-y-8 justify-self-end lg:max-w-full">
              <CardProjectDetail
                className="py-0 lg:gap-6 lg:px-0 lg:py-0 [&_.pj-detail-cell-label]:text-[var(--color-label)] [&_.pj-detail-cell-label]:opacity-100"
                classNameRows="grid last-of-type:!col-span-full grid-cols-2 md:gap-y-4 gap-y-6 md:gap-x-6 gap-x-12"
                projectDescription={{
                  location: project?.location?.name,
                  address: project.address,
                  developer: project.developer?.name,
                  totalUnits:
                    project?.manualTotalUnitsCount > 0
                      ? project?.manualTotalUnitsCount.toString()
                      : project?.totalUnits > 0
                      ? project?.totalUnits.toString()
                      : t("not_available.units"),
                  tenure: TENURE_ITEM[project.tenure]?.name,
                  expectedTop: project?.expectedTop
                    ? dayjs(project?.expectedTop).format("DD MMMM YYYY")
                    : t("not_available.top"),
                  projectCategory: project.category?.name,
                  googleMapUrl: "",
                }}
              />
            </div>
          </div>
        </div>
      </LDPSection>
    );
  }
);

LDPProjectDetail.displayName = "LDPProjectDetail";

export default memo(LDPProjectDetail);
