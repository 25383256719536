import * as React from "react";

import { IContentSection } from "../organisms/ContentsNavigator/types";
import HeaderLDP, { HeaderProps } from "../organisms/HeaderLDP";
import HeaderMobileLDP, {
  HeaderMobileProps,
} from "../organisms/HeaderMobileLDP";

import { IMediaImageUrls } from "~/entities/media";
import { useIsClient } from "~/hooks/use-is-client";

export interface LDPHeaderProps extends HeaderProps, HeaderMobileProps {
  validProjectMenuItems: IContentSection[];
  logoMobile?: IMediaImageUrls;
  editorMode?: boolean;
}

const prefixObject = "section-";

export default function LDPHeader({
  logo: logoHeader,
  project,
  topButton,
  validProjectMenuItems,
  phoneNumber,
  socialLinks,
  top,
  logoMobile,
  editorMode,
}: LDPHeaderProps) {
  const isClient = useIsClient();
  const [styleSection, setStyleSection] = React.useState<any>(null);

  React.useEffect(() => {
    const allSections = document.querySelectorAll(
      "section[data-type='ldp-section']"
    );

    const allVarStyle = Object.fromEntries(
      Array.from(allSections).map((elm, idx) => {
        const styles = elm.getAttribute("style")?.split("; "); // Use computed styles to get all CSS vars
        const cssVars: Record<string, string> = {}; // Explicit typing for TypeScript
        if (styles) {
          for (let i = 0; i < styles.length; i++) {
            const prop = styles[i];
            if (prop?.trim?.().startsWith?.("--")) {
              const [key, value] = prop?.split?.(": ") || [];
              cssVars[key?.trim?.()] = value?.trim?.();
            }
          }
        }
        return [`${prefixObject}${idx}`, cssVars];
      })
    );

    setStyleSection(allVarStyle);
  }, []);

  return (
    <>
      <HeaderLDP
        logo={logoHeader}
        project={project}
        topButton={topButton}
        sections={validProjectMenuItems || ([] as IContentSection[])}
        defaultSection={validProjectMenuItems?.[0] || ({} as IContentSection)}
        className={editorMode ? "pointer-events-none select-none" : ""}
      />
      <HeaderMobileLDP
        logo={logoMobile || logoHeader}
        project={project}
        projectMenuItems={validProjectMenuItems || ([] as IContentSection[])}
        phoneNumber={phoneNumber || ""}
        socialLinks={socialLinks || []}
        topButton={topButton}
        top={top}
        className={editorMode ? "pointer-events-none select-none" : ""}
      />
    </>
  );
}
