import dayjs from "dayjs";
import { forwardRef, memo } from "react";
import { useTranslation } from "react-i18next";

import LDPSection, { LDPSectionProps } from "../../LDPSection";

import CleanedContent from "~/components/atoms/CleanedContent";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import CardProjectDetail from "~/components/molecules/CardProjectDetail";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O2,
} from "~/utilities/config/landingPageConfig";
import { TENURE_ITEM } from "~/utilities/constants/tenureItems";

export interface LDPProjectDetailProps extends LDPSectionProps {
  className?: string;
  id: string;
  title?: string;
  description?: string;

  project: IProject;
  image: IMediaImageUrls;
}

export type Ref = HTMLDivElement;

const LDPProjectDetail = forwardRef<Ref, LDPProjectDetailProps>(
  (
    {
      className = "",
      id,
      title,
      styleRoot = VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPProjectDetail],
      project,
      image,
      description,
    },
    ref
  ) => {
    const { t } = useTranslation();
    if (!project) return null;

    const isShowLine = title || description || project?.description;

    return (
      <LDPSection
        ref={ref}
        id={id}
        className={cn("pj-detail mx-auto py-24 lg:py-12", className)}
        styleRoot={styleRoot}
      >
        <div className="grid grid-cols-2 gap-6 lg:grid-cols-1 lg:gap-8 ">
          <div className="relative col-span-1 pb-0 lg:mr-[43px]  lg:pb-10">
            <ImageResponsive
              imageData={image}
              className="relative z-10 aspect-[1/1] min-h-[240px]  w-full max-w-[530px] rounded-bl-[30%]  rounded-tr-[30%] object-cover object-center  lg:max-w-full "
              loading="eager"
            />
            <div className="bg-box-curve absolute -bottom-24 -left-24 aspect-[708/530] h-auto w-[calc(100%+6rem)] rounded-tr-[30%] bg-[--bg-inner] lg:-left-4 lg:bottom-0 lg:aspect-[375/276] lg:w-full lg:min-w-[100vw]" />
          </div>

          <div className="col-span-1">
            <div className="flex w-full max-w-[530px] flex-col gap-y-8 justify-self-end lg:max-w-full">
              <div className="flex flex-col  gap-y-6">
                {title && (
                  <CleanedContent
                    htmlContent={title || "Project Detail" || ""}
                    className="!text-header-56 font-bold text-[var(--main-color)] lg:!text-header-32"
                  />
                )}

                {(description || project?.description) && (
                  <CleanedContent
                    htmlContent={description || project?.description || ""}
                    className="h-full w-full text-body-title-16 text-[var(--main-color)]"
                  />
                )}
              </div>

              {isShowLine && (
                <hr className="h-0.5 bg-[var(--main-color)] text-[var(--main-color)]" />
              )}

              <CardProjectDetail
                className="py-0  lg:gap-6 lg:px-0 lg:py-0  [&_.pj-detail-cell-desc]:text-[var(--main-color)] [&_.pj-detail-cell-label]:text-[var(--color-label)] [&_.pj-detail-cell-label]:opacity-100"
                classNameRows="grid last-of-type:!col-span-full grid-cols-2 md:gap-y-4 gap-y-6 md:gap-x-6 gap-x-12"
                projectDescription={{
                  location: project?.location?.name,
                  address: project.address,
                  developer: project.developer?.name,
                  totalUnits:
                    project?.manualTotalUnitsCount > 0
                      ? project?.manualTotalUnitsCount.toString()
                      : project?.totalUnits > 0
                      ? project?.totalUnits.toString()
                      : t("not_available.units"),
                  tenure: TENURE_ITEM[project.tenure]?.name,
                  expectedTop: project?.expectedTop
                    ? dayjs(project?.expectedTop).format("DD MMMM YYYY")
                    : t("not_available.top"),
                  projectCategory: project.category?.name,
                  googleMapUrl: "",
                }}
              />
            </div>
          </div>
        </div>
      </LDPSection>
    );
  }
);

LDPProjectDetail.displayName = "LDPProjectDetail";

export default memo(LDPProjectDetail);
