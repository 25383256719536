import React, { useState, useEffect } from "react";
import * as ReactColor from "react-color";
import { Popover } from "react-tiny-popover";
import { cn } from "~/utilities/cn";

export interface ColorPickerPopoverProps {
  value: string;
  onChange: (color: string) => void;
  className?: string;
  name?: string;
  label?: string;
}

const ColorPickerPopover: React.FC<ColorPickerPopoverProps> = ({
  value,
  onChange,
  className,
  name,
  label,
}) => {
  // console.log("value picker", name, value);
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState<string>(value);

  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChange = (color: ReactColor.ColorResult) => {
    const { r, g, b, a } = color.rgb;
    const rgbaValue = `rgba(${r}, ${g}, ${b}, ${a})`;
    setColor(rgbaValue);
    onChange(rgbaValue);
  };

  const getColorFormat = (color: string) => {
    if (color.startsWith("rgba")) return color;
    if (color.length === 9) {
      // Handle hex with alpha (e.g., #FFFFFF40)
      const r = parseInt(color.slice(1, 3), 16);
      const g = parseInt(color.slice(3, 5), 16);
      const b = parseInt(color.slice(5, 7), 16);
      const a = parseInt(color.slice(7, 9), 16) / 255;
      return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    return color;
  };

  // useEffect(() => {
  //   const colorFormat = getColorFormat(value);

  //   console.log("format", colorFormat);
  //   setColor(colorFormat);
  //   onChange(colorFormat);
  // }, [value]);

  return (
    <div className={cn(className, "h-full leading-none")}>
      <Popover
        isOpen={showPicker}
        positions={["top", "right", "left", "bottom"]} // preferred positions by priority
        content={
          <div className="custom-sketch-picker">
            <ReactColor.SketchPicker
              color={getColorFormat(color)}
              onChange={handleChange}
            />
          </div>
        }
        containerClassName="z-[1000]"
        onClickOutside={() => setShowPicker(false)}
      >
        <div className="flex h-full flex-col justify-between gap-2">
          {label && (
            <label className="text-[10px] font-semibold" htmlFor={name}>
              {label}
            </label>
          )}
          <input
            type="button"
            name={name}
            id={name}
            value={color}
            className="h-10 w-full rounded border border-black15 text-red-500 text-opacity-0 placeholder:text-opacity-0"
            style={{ backgroundColor: color }}
            onClick={() => setShowPicker(!showPicker)}
          />
        </div>
      </Popover>
    </div>
  );
};

export default ColorPickerPopover;
