
export enum ELDPOption {
    O1 = "O1",
    O2 = "O2",
    O3 = "O3",
}


export type TLandingPageOption = {
    id: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string | null,
    name: ELDPOption,
    option: string
}

export type TLandingPge = {
    id: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string | null,
    projectId: string,
    userId: string,
    domain: string,
    name: string,
    layout: null | string,
    type: null,
    layoutOption: null,
    headerScript: string | null,
    bodyScript: string | null,
    enquiryScript: string | null,
    useEnquiryScript: boolean | null
}

export type OptionMapping = {
    id: string;
    createAt: string;
    updateAt: string;
    deletedAt: string | null;
    active: boolean;
    landingPage: TLandingPge;
    landingPageOption: TLandingPageOption,
}

export type TUserLandingPageDTO = {
    ldpId: string,
    ldpName: string,
    ldpDomain: string,
    puckConfig?: string
}