import LDPSection, { LDPSectionProps } from "../../LDPSection";

import CleanedContent from "~/components/atoms/CleanedContent";
import { IMediaImageUrls } from "~/entities/media";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";

export interface LDPLatestUpdateProps extends LDPSectionProps {
  titleHtml?: string;
  image?: IMediaImageUrls;
  descriptionHtml?: string;
}

export default function LDPLatestUpdate({
  styleRoot,
  titleHtml,
  descriptionHtml,
  image,
}: LDPLatestUpdateProps) {
  return (
    <LDPSection
      className="py-[98px] lg:py-12"
      styleRoot={{
        ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPLatestUpdate],
        ...styleRoot,
      }}
    >
      <div className="flex  flex-row gap-[130px] lg:flex-col lg:gap-6">
        <div className="w-[506px]">
          <CleanedContent
            className="text-header-48 font-bold !text-[var(--main-color)] lg:w-full lg:!text-[28px]/[34px]"
            htmlContent={titleHtml || `Latest Update`}
          />
        </div>

        <div className="flex flex-1  flex-col gap-y-12 lg:gap-y-8">
          <div className="mr-5 flex flex-col gap-y-3 ">
            <CleanedContent
              className="text-[var(--main-color)]"
              htmlContent={descriptionHtml || "Latest Updated content"}
            />
          </div>
        </div>
      </div>
    </LDPSection>
  );
}
