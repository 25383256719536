import LDPSection, { LDPSectionProps } from "../../LDPSection";
import LDPTitle from "../../LDPTitle";
import LDPVideo from "../../LDPVideo";

import CleanedContent from "~/components/atoms/CleanedContent";
import { Icon } from "~/components/atoms/Icons";
import Image from "~/components/atoms/Image";
import { IMediaImageUrls, IMediaVideoUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import { EIcon } from "~/utilities/enums/Icons";

export interface LDPProjectVideoProps extends LDPSectionProps {
  title: string;
  descriptionHtml: string;
  videoMedia?: IMediaVideoUrls;
  thumbnail?: IMediaImageUrls;
  partnerLogo?: string;
}

export default function LDPProjectVideo({
  title,
  descriptionHtml,
  styleRoot,
  thumbnail,
  videoMedia,
  partnerLogo,
  className,
  ...rest
}: LDPProjectVideoProps) {
  return (
    <LDPSection
      styleRoot={{
        ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPProjectVideo],
        ...styleRoot,
      }}
      className={cn(
        "relative  w-full overflow-hidden py-[96px] lg:py-12 [&_.ldp-video]:rounded-3xl lg:[&_.ldp-video]:rounded-xl",
        className
      )}
      {...rest}
    >
      <div className="circle absolute bottom-0 right-0  max-w-[1237px]  translate-x-1/3 translate-y-[40%]  bg-[var(--circle-1)]  lg:max-w-[450px] lg:translate-y-1/4" />

      <div className="relative z-10 flex flex-col gap-16 lg:gap-12">
        <div className="circle absolute left-0 top-0 max-w-[217px] translate-x-1/2 bg-[--circle-2] lg:max-w-[76px]  lg:-translate-y-1/2 lg:translate-x-0" />

        <div className="relative z-10 flex flex-1 flex-col items-center gap-6   lg:mr-0 ">
          <LDPTitle
            title={title}
            className="ldp-video-title [&>*]:text-[var(--color-text)]"
          />

          <div className="flex flex-row justify-center gap-6 lg:gap-4">
            <Icon
              className="mb-auto max-w-max lg:max-w-[18px] [&_path]:fill-[var(--color-text)] "
              TypeIcon={EIcon.QuoteIcon}
            />
            <CleanedContent
              className="w-full max-w-[50%] text-center text-[var(--color-text)] opacity-75 lg:max-w-full lg:text-start"
              htmlContent={descriptionHtml}
            />
            <Icon
              className="mt-auto block max-w-max -rotate-180 lg:hidden lg:max-w-[18px] [&_path]:fill-[var(--color-text)] "
              TypeIcon={EIcon.QuoteIcon}
            />
          </div>

          {partnerLogo && (
            <Image
              url={partnerLogo}
              alt="partnerLogo"
              className="h-auto w-full max-w-[140px]"
            />
          )}
        </div>

        <div className="ldp-video-container   mx-auto w-full max-w-5xl">
          <LDPVideo
            className="h-[583px] overflow-hidden lg:h-auto"
            videoMedia={videoMedia}
            thumbnail={thumbnail}
          />
        </div>
      </div>
    </LDPSection>
  );
}
