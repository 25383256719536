import { ColorPickerItem } from "~/components/molecules/ColorPickerGroup";
import colors from "~/styles/colors";
import { ELDPOption } from "~/types/landingPage";


export const setupVariableSectionColor = ({ groupSectionColors, specialVariableColors }: {
    groupSectionColors: Record<Partial<ESectionLDP> | string, ColorPickerItem[]>
    specialVariableColors?: Record<Partial<ESectionLDP> | string, Record<string, string>>
}) => {
    return Object.fromEntries(Object.entries(groupSectionColors).map(([sectionName, value]: [string, ColorPickerItem[]]) => {
        const specialObj = specialVariableColors?.[sectionName] || {}
        const variablesSection = Object.fromEntries(value.map(item => [item.name, item.defaultValue]))

        const colorsVariable = {
            ...variablesSection,
            ...specialObj
        }
        return [sectionName, colorsVariable]
    }))

}

export const VARIABLE_COLORS_ROOT: Record<Partial<ELDPOption> | string, any> = {
    O3: {
        "--bg-page": "#ffffff",
        "--bg-dark": "#0F393A",
        "--bg-light": "#CBE8D2",
        "--color-light": "#ffffff",
        "--color-dark": "#0F393A",
        "--color-circle-1": "#F4F3B2",
        "--color-circle-2": "#CBE8D2",
    },
    O2: {
        "--bg-page": "#FFFCF5",
        "--bg-dark": "#0F393A",
        "--bg-light": "#CBE8D2",
        "--color-light": "#FFFCF5",
        "--color-dark": "#0F393A",
    },
    O1: {
        "--bg-page": "#ffffff",
        "--bg-dark": "#0F393A",
        "--bg-light": "#CBE8D2",
        "--color-light": "#FFFCF5",
        "--color-dark": "#0F393A",
    },

    FOOTER: {
        "--color-footer-background": colors.footerBackground,
        "--color-footer-text": colors.footerText
    }

}





export const GROUP_COLORS_ROOT: Record<Partial<ELDPOption> | string, ColorPickerItem[]> = {
    [ELDPOption.O3]: [
        {
            defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3["--bg-page"]})`,
            label: "Background Page",
            name: "--bg-page"
        },
        {
            defaultValue: `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3["--bg-dark"]})`,
            label: "Background Dark",
            name: "--bg-dark",
        },
        {
            defaultValue: `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3["--bg-light"]})`,
            label: "Background Light",
            name: "--bg-light",
        },
        {
            defaultValue: `var(--color-light, ${VARIABLE_COLORS_ROOT.O3["--color-light"]})`,
            label: "Color Light",
            name: "--color-light",
        },
        {
            defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`,
            label: "Color Dark",
            name: "--color-dark",
        },
        {
            defaultValue: `var(--color-circle-1, ${VARIABLE_COLORS_ROOT.O3["--color-circle-1"]})`,
            label: "Color Circle 1",
            name: "--color-circle-1",
        },
        {
            defaultValue: `var(--color-circle-2, ${VARIABLE_COLORS_ROOT.O3["--color-circle-2"]})`,
            label: "Color Circle 2",
            name: "--color-circle-2",
        }
    ],

    [ELDPOption.O2]: [
        {
            defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O2["--bg-page"]})`,
            label: "Background Page",
            name: "--bg-page"
        },
        {
            defaultValue: `var(--bg-light, ${VARIABLE_COLORS_ROOT.O2["--bg-light"]})`,
            label: "Background Color Image",
            name: "--bg-light",
        },
        {
            defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O2["--color-dark"]})`,
            label: "Text color",
            name: "--color-dark",
        },
    ],

    [ELDPOption.O1]: [
        {
            defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O1["--bg-page"]})`,
            label: "Background Page",
            name: "--bg-page"
        },
        {
            defaultValue: `var(--bg-light, ${VARIABLE_COLORS_ROOT.O1["--bg-light"]})`,
            label: "Background Color Image",
            name: "--bg-light",
        },
        {
            defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O1["--color-dark"]})`,
            label: "Text color",
            name: "--color-dark",
        },
    ],

    FOOTER: [
        {
            defaultValue: `var(--color-footer-background)`,
            label: "Background Footer",
            name: "--color-footer-background"
        },
        {
            defaultValue: `--color-footer-text`,
            label: "Footer text color",
            name: "--color-footer-text",
        },
    ]
}


export enum ESectionLDP {
    LDPMastheadGallery = 'LDPMastheadGallery',
    LDPLatestUpdate = 'LDPLatestUpdate',
    LDPProjectDetail = 'LDPProjectDetail',
    LDPProjectVideo = 'LDPProjectVideo',
    LDPPartners = 'LDPPartners',
    LDPMarketing = 'LDPMarketing',
    LDPUnits = 'LDPUnits',
    LDPLocation = 'LDPLocation',
    LDPVirtualTour = 'LDPVirtualTour',
    LDPContactSales = 'LDPContactSales',
    LDPSitePlan = 'LDPSitePlan',
    LDPGallery = 'LDPGallery'
}

export const VARIABLE_COLORS_SECTIONS_O3: Record<Partial<ESectionLDP> | string, any> = {

    [ESectionLDP.LDPMastheadGallery]: {
        "--bg-section": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
        "--main-color": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
    },

    [ESectionLDP.LDPLatestUpdate]: {
        "--bg-section": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
        "--main-color": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
    },

    [ESectionLDP.LDPProjectDetail]: {
        "--bg-section": `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3['--bg-dark']})`,
        "--color-text": `var(--color-light, ${VARIABLE_COLORS_ROOT.O3['--color-light']})`,
        "--color-label": "#F4F3B280",
        "--color-color": `var(--color-light, ${VARIABLE_COLORS_ROOT.O3['--color-light']})`,
    },

    [ESectionLDP.LDPProjectVideo]: {
        "--bg-section": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
        "--color-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--circle-1": `var(--color-circle-1, ${VARIABLE_COLORS_ROOT.O3["--color-circle-1"]})`,
        "--circle-2": `var(--color-circle-2, ${VARIABLE_COLORS_ROOT.O3["--color-circle-2"]})`,
    },


    [ESectionLDP.LDPPartners]: {
        "--bg-section": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
        "--color-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--color-color": `${VARIABLE_COLORS_ROOT.O3['--color-text']})`,
    },

    [ESectionLDP.LDPMarketing]: {
        "--bg-section": `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3['--bg-dark']})`,
        "--color-text": `var(--color-light, ${VARIABLE_COLORS_ROOT.O3['--color-light']})`,
        "--color-color": `var(--color-light, ${VARIABLE_COLORS_ROOT.O3['--color-light']})`,
    },

    [ESectionLDP.LDPUnits]: {
        "--bg-section": `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3['--bg-light']})`,
        "--color-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--color-table-head-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--color-table-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--color-table-row-divider": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--bg-body-table": `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3['--bg-light']})`,
        "--bg-head-table": `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3['--bg-light']})`,
        "--line-active-tab": "#D03243"
    },

    [ESectionLDP.LDPLocation]: {
        "--bg-section": `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3['--bg-light']})`,
        "--color-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--color-project-heading-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
    },


    [ESectionLDP.LDPVirtualTour]: {
        "--bg-section": `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3['--bg-dark']})`,
        "--color-text": `var(--color-light, ${VARIABLE_COLORS_ROOT.O3['--color-light']})`,
        "--color-project-heading-text": `var(--color-light, ${VARIABLE_COLORS_ROOT.O3['--color-light']})`,
        "--color-color": 'var(--color-text)' // overwrite default color to --color-text
    },

    [ESectionLDP.LDPContactSales]: {
        "--bg-section": `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3['--bg-dark']})`,
        "--color-enquiry-checkbox-border": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`,
        "--color-enquiry-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`,
        "--color-color": `var(--color-enquiry-text)`,
        "--bg-contact-form": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
    },


    [ESectionLDP.LDPSitePlan]: {
        "--bg-section": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
        "--color-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,

    },

    [ESectionLDP.LDPGallery]: {
        "--bg-section": `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3['--bg-page']})`,
        "--color-text": `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
        "--circle-1": `var(--color-circle-1, ${VARIABLE_COLORS_ROOT.O3["--color-circle-1"]})`,
        "--circle-2": `var(--color-circle-2, ${VARIABLE_COLORS_ROOT.O3["--color-circle-2"]})`,
    },


}




export const GROUP_COLORS_SECTIONS_O3: Record<Partial<ESectionLDP> | string, ColorPickerItem[]> = {

    [ESectionLDP.LDPMastheadGallery]: [
        { defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3["--bg-page"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--main-color" },
    ],

    [ESectionLDP.LDPLatestUpdate]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--main-color" },

    ],

    [ESectionLDP.LDPProjectDetail]: [
        { defaultValue: `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3["--bg-dark"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-light, ${VARIABLE_COLORS_ROOT.O3["--color-light"]})`, label: "Text Color", name: "--color-text" },

        { defaultValue: "#F4F3B280", label: "Label Color", name: "--color-label" },
    ],

    [ESectionLDP.LDPProjectVideo]: [
        { defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3["--bg-page"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },

        { defaultValue: `var(--color-circle-1, ${VARIABLE_COLORS_ROOT.O3["--color-circle-1"]})`, label: "Circle 1", name: "--circle-1" },
        { defaultValue: `var(--color-circle-2, ${VARIABLE_COLORS_ROOT.O3["--color-circle-2"]})`, label: "Circle 2", name: "--circle-2" },
    ],

    [ESectionLDP.LDPPartners]: [
        { defaultValue: `var(--bg-page, $--bg-page, {VARIABLE_COLORS_ROOT.O3["--bg-page"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-text, ${VARIABLE_COLORS_ROOT.O3["--color-text"]})`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPMarketing]: [
        { defaultValue: `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3["--bg-dark"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-light, ${VARIABLE_COLORS_ROOT.O3["--color-light"]})`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPUnits]: [
        { defaultValue: `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3["--bg-light"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },
        {
            name: "--color-table-head-text",
            defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
            label: "Color Table Header Text"
        },
        {
            name: "--color-table-text",
            defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
            label: "Color Table Text"

        },
        {
            name: "--color-table-row-divider",
            defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3['--color-dark']})`,
            label: "Color Table Divider"

        },

        { defaultValue: "#D03243", label: "Active Tab Line", name: "--line-active-tab" },
    ],

    [ESectionLDP.LDPLocation]: [
        { defaultValue: `var(--bg-light, ${VARIABLE_COLORS_ROOT.O3["--bg-light"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPVirtualTour]: [
        { defaultValue: `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3["--bg-dark"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-light, ${VARIABLE_COLORS_ROOT.O3["--color-light"]})`, label: "Text Color", name: "--color-text" },
        { defaultValue: `var(--color-light, ${VARIABLE_COLORS_ROOT.O3["--color-light"]})`, label: "Project Heading Color", name: "--color-project-heading-text" },
    ],

    [ESectionLDP.LDPContactSales]: [
        { defaultValue: `var(--bg-dark, ${VARIABLE_COLORS_ROOT.O3["--bg-dark"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Contact Sales Text", name: "--color-contact-sales-text" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Contact Sales Checkbox Border", name: "--color-enquiry-checkbox-border" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Enquiry Text Color", name: "--color-enquiry-text" },
        { defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3["--bg-page"]})`, label: "Contact Form Background", name: "--bg-contact-form" },
    ],

    [ESectionLDP.LDPSitePlan]: [
        { defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3["--bg-page"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPGallery]: [
        { defaultValue: `var(--bg-page, ${VARIABLE_COLORS_ROOT.O3["--bg-page"]})`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },

        { defaultValue: `var(--color-circle-1, ${VARIABLE_COLORS_ROOT.O3["--color-circle-1"]})`, label: "Circle 1", name: "--circle-1" },
        { defaultValue: `var(--color-circle-2, ${VARIABLE_COLORS_ROOT.O3["--color-circle-2"]})`, label: "Circle 2", name: "--circle-2" },
    ],
};


export const GROUP_COLORS_SECTIONS_O2: Record<Partial<ESectionLDP> | string, ColorPickerItem[]> = {

    [ESectionLDP.LDPMastheadGallery]: [
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--main-color" },
    ],

    [ESectionLDP.LDPLatestUpdate]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--main-color" },
    ],

    [ESectionLDP.LDPProjectDetail]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--main-color" },
        { defaultValue: `var(--color-dark)`, label: "Label Color", name: "--color-label" },
        { defaultValue: "var(--bg-light)", label: "Background Inner", name: "--bg-inner" },
    ],

    [ESectionLDP.LDPProjectVideo]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--main-color" },


    ],

    [ESectionLDP.LDPPartners]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPMarketing]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--bg-light)`, label: "Background Inner", name: "--bg-inner" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },
    ],

    [ESectionLDP.LDPUnits]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },
        {
            name: "--color-table-head-text",
            defaultValue: `var(--color-dark)`,
            label: "Color Table Header Text"
        },
        {
            name: "--color-table-text",
            defaultValue: `var(--color-dark)`,
            label: "Color Table Text"

        },
        {
            name: "--color-table-row-divider",
            defaultValue: `var(--color-dark)`,
            label: "Color Table Divider"

        },

        { defaultValue: "#D03243", label: "Active Tab Line", name: "--line-active-tab" },
    ],

    [ESectionLDP.LDPLocation]: [
        { defaultValue: `var(--bg-dark)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-light)`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPVirtualTour]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPContactSales]: [
        { defaultValue: `var(--bg-light)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Contact Sales Checkbox Border", name: "--color-enquiry-checkbox-border" },
        { defaultValue: `var(--color-dark)`, label: "Enquiry Text Color", name: "--color-enquiry-text" },
        { defaultValue: `var(--bg-page)`, label: "Contact Form Background", name: "--bg-contact-form" },
    ],

    [ESectionLDP.LDPSitePlan]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: "var(--bg-light)", label: "Background Inner", name: "--bg-inner" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },
    ],



    [ESectionLDP.LDPGallery]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-color" },
    ],
};


export const GROUP_COLORS_SECTIONS_O1: Record<Partial<ESectionLDP> | string, ColorPickerItem[]> = {

    [ESectionLDP.LDPMastheadGallery]: [
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--main-color" },
    ],

    [ESectionLDP.LDPLatestUpdate]: [
        { defaultValue: `#F1F0EE`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--main-color" },
    ],

    [ESectionLDP.LDPProjectDetail]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-color" },
    ],

    [ESectionLDP.LDPProjectVideo]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--main-color" },


    ],

    [ESectionLDP.LDPPartners]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPMarketing]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--bg-light)`, label: "Background Inner", name: "--bg-inner" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },
    ],

    [ESectionLDP.LDPUnits]: [
        { defaultValue: `#F1F0EE`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },
        {
            name: "--bg-table-head",
            defaultValue: `var(--color-dark)`,
            label: "Background Table Header"
        },
        {
            name: "--color-table-head-text",
            defaultValue: `#ffffff`,
            label: "Color Table Header Text"
        },
        {
            name: "--color-table-text",
            defaultValue: `var(--color-dark)`,
            label: "Color Table Text"

        },
        {
            name: "--color-table-row-divider",
            defaultValue: `var(--color-dark)`,
            label: "Color Table Divider"
        },

        { defaultValue: "#ffffff", label: "Text Active Tab", name: "--color-active" },
        { defaultValue: "var(--color-dark, #0F393A)", label: "Background Active Tab", name: "--bg-active" },

    ],

    [ESectionLDP.LDPLocation]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPVirtualTour]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-text" },

    ],

    [ESectionLDP.LDPContactSales]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Contact Sales Checkbox Border", name: "--color-enquiry-checkbox-border" },
        { defaultValue: `var(--color-dark)`, label: "Enquiry Text Color", name: "--color-enquiry-text" },
        // { defaultValue: `var(--bg-page)`, label: "Contact Form Background", name: "--bg-contact-form" },
    ],

    [ESectionLDP.LDPSitePlan]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: "var(--bg-light)", label: "Background Inner", name: "--bg-inner" },
        { defaultValue: `var(--color-dark, ${VARIABLE_COLORS_ROOT.O3["--color-dark"]})`, label: "Text Color", name: "--color-text" },
    ],



    [ESectionLDP.LDPGallery]: [
        { defaultValue: `var(--bg-page)`, label: "Background Section", name: "--bg-section" },
        { defaultValue: `var(--color-dark)`, label: "Text Color", name: "--color-color" },
    ],
};


export const VARIABLE_COLORS_SECTIONS_O2: Record<Partial<ESectionLDP> | string, any> = setupVariableSectionColor({
    groupSectionColors: GROUP_COLORS_SECTIONS_O2,
})

export const VARIABLE_COLORS_SECTIONS_O1: Record<Partial<ESectionLDP> | string, any> = setupVariableSectionColor({
    groupSectionColors: GROUP_COLORS_SECTIONS_O1,
})


