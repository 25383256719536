import { ReactNode, useMemo, useState } from "react";

import LDPSection, { LDPSectionProps } from "../../LDPSection";
import LDPTitle from "../../LDPTitle";

import { Icon } from "~/components/atoms/Icons";
import Media from "~/components/atoms/Media";
import Typo from "~/components/atoms/Typo";
import ModalMediaGallery from "~/components/organisms/ModalMediaGallery";
import { TMedia } from "~/entities/media";
import { IProject } from "~/entities/project";
import useMediaQuery from "~/hooks/use-mediaquery";
import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface LDPGalleryProps extends LDPSectionProps {
  title?: string;
  description?: string;
  project: IProject;
  collapseOptions?: {
    viewMoreText?: string;
    viewLessText?: string;
    enable?: boolean;
    icon?: ReactNode;
  };
  medias?: TMedia[];
}

export default function LDPGallery({
  id,
  title = "Gallery",
  description,
  project,
  collapseOptions = {
    viewLessText: "View less",
    viewMoreText: "View more",
    enable: true,
  },

  styleRoot,

  medias = project?.medias || [],
}: LDPGalleryProps) {
  const [galley, setGallery] = useState({
    isOpen: false,
    currenIndex: 0,
  });

  const isMobile = useMediaQuery("lg");

  const [collapse, setCollapse] = useState(false);

  const limit = useMemo(() => {
    return isMobile ? 3 : 5;
  }, [isMobile]);

  const mediaConvert = useMemo(() => {
    if (!medias || medias.length === 0) return [];
    if (!collapseOptions.enable) return medias;
    if (collapse) return medias;

    return medias.slice(0, limit);
  }, [medias, limit, collapse, collapseOptions.enable]);

  const handleOpen = (idx: number) => {
    setGallery({
      currenIndex: idx,
      isOpen: true,
    });
  };

  const handleClose = () => {
    setGallery({
      currenIndex: 0,
      isOpen: false,
    });
  };

  return (
    <>
      <LDPSection styleRoot={styleRoot} classNameSection="py-12" id={id}>
        <div className="flex flex-col items-center gap-y-12 lg:gap-y-8">
          <div className="flex flex-col items-center gap-y-8">
            <LDPTitle
              className="text-color [&_*]:text-center"
              title={title}
              description={description}
            />
          </div>
          <div className="ldp-gallery grid h-full w-full grid-flow-dense grid-cols-6  gap-6  lg:gap-4">
            {mediaConvert.map((item, idx) => {
              const positionIdx = idx % limit;
              const isSpanHalf = positionIdx < Math.floor(limit / 2);

              return (
                <button
                  className={cn(
                    "min-h-[458px]",
                    isSpanHalf
                      ? "col-span-3 lg:col-span-6 lg:min-h-[256px]"
                      : "col-span-2 lg:col-span-3 lg:min-h-[122px]"
                  )}
                  key={item.id}
                  onClick={() => handleOpen(idx)}
                >
                  <Media
                    pictureClassName="block"
                    className=" h-full w-full"
                    media={item}
                  />
                </button>
              );
            })}
          </div>

          {collapseOptions.enable && medias?.length > limit && (
            <div className="flex w-full items-center justify-center">
              <button
                type="button"
                onClick={() => setCollapse(!collapse)}
                className="flex cursor-pointer items-center justify-center gap-1 hover:underline"
              >
                <Typo
                  tag={ETypoTag.SPAN}
                  variant={ETypoVariant.BODY_TITLE_14}
                  className="font-bold"
                >
                  {!collapse
                    ? collapseOptions.viewMoreText
                    : collapseOptions.viewLessText}
                </Typo>

                <Icon
                  TypeIcon={EIcon.ChevronDownIcon}
                  height={20}
                  width={20}
                  className={cn(
                    "fill-white60 transition-all duration-300",
                    collapse && "rotate-180"
                  )}
                />
              </button>
            </div>
          )}
        </div>
      </LDPSection>

      {galley.isOpen && (
        <ModalMediaGallery
          medias={project?.medias}
          closeModal={handleClose}
          isOpen={galley.isOpen}
          indexSlide={galley.currenIndex}
        />
      )}
    </>
  );
}
