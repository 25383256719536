import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import isEmpty from "lodash/isEmpty";
import { ReactElement, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import LDPSection, { LDPSectionProps } from "../LDPSection";

import Button from "~/components/atoms/Button";
import Typo from "~/components/atoms/Typo";
import SectionProjectAvailableUnits from "~/components/sections/SectionProjectAvailableUnits";
import SectionProjectFloorPlans from "~/components/sections/SectionProjectFloorPlans";
import SectionProjectSaleTransaction from "~/components/sections/SectionProjectSaleTransaction";
import SectionProjectUnitMix from "~/components/sections/SectionProjectUnitMix";
import { IFloorPlan, IFloorPlanFilterOption } from "~/entities/floorPlan";
import { IProject } from "~/entities/project";
import {
  ISaleTransactionFilterOption,
  IUnitTransaction,
} from "~/entities/unitTransaction";
import useMediaQuery from "~/hooks/use-mediaquery";
import { IPaginateResponseData } from "~/types";
import { cn } from "~/utilities/cn";
import projectSectionConfig from "~/utilities/config/projectSectionConfig";
import { Section } from "~/utilities/enums/ProjectSections";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface LDPUnitsProps extends LDPSectionProps {
  className?: string;
  project: IProject;
  floorPlansPagination: IPaginateResponseData<IFloorPlan>;
  floorPlanFilterOptions: IFloorPlanFilterOption[];
  unitTransactions: IPaginateResponseData<IUnitTransaction>;
  saleTransactionFilterOptions: ISaleTransactionFilterOption[];
}

export interface IUnitsTab {
  title: ReactNode;
  TabPanel: ReactElement;
  hidden: boolean;
  targetId: string;
}

export default function LDPUnits({
  project,
  floorPlanFilterOptions,
  floorPlansPagination,
  unitTransactions,
  saleTransactionFilterOptions,
  className,
  styleRoot,
  ...rest
}: LDPUnitsProps) {
  const { t } = useTranslation();

  const tabsList: IUnitsTab[] = useMemo(() => {
    return [
      {
        TabPanel: (
          <SectionProjectUnitMix
            id={Section.UNIT_MIX}
            title={projectSectionConfig[Section.UNIT_MIX].title}
            description={projectSectionConfig[Section.UNIT_MIX].description}
            project={project}
            className="lg:gap-6 lg:py-1.5"
          />
        ),

        hidden: isEmpty(project?.unitTypes),
        title: t(projectSectionConfig[Section.UNIT_MIX].title),
        targetId: Section.UNIT_MIX,
      },
      {
        TabPanel: (
          <SectionProjectAvailableUnits
            id={Section.AVAILABLE_UNITS}
            title={projectSectionConfig[Section.AVAILABLE_UNITS].title}
            description={
              projectSectionConfig[Section.AVAILABLE_UNITS].description
            }
            project={project}
          />
        ),
        hidden: isEmpty(
          project.unitTypes.filter((item) => !!item.availableUnits)
        ),
        title: t(projectSectionConfig[Section.AVAILABLE_UNITS].title),
        targetId: Section.AVAILABLE_UNITS,
      },
      {
        TabPanel: (
          <SectionProjectFloorPlans
            id={Section.FLOOR_PLANS}
            title={projectSectionConfig[Section.FLOOR_PLANS].title}
            description={projectSectionConfig[Section.FLOOR_PLANS].description}
            floorPlansPagination={floorPlansPagination}
            project={project}
            unitTypeFilterOptions={floorPlanFilterOptions}
          />
        ),
        hidden: isEmpty(floorPlansPagination.items),
        title: t(projectSectionConfig[Section.FLOOR_PLANS].title),
        targetId: Section.FLOOR_PLANS,
      },

      {
        TabPanel: (
          <SectionProjectSaleTransaction
            id={Section.SALES_TRANSACTION}
            title={projectSectionConfig[Section.SALES_TRANSACTION].title}
            description={
              projectSectionConfig[Section.SALES_TRANSACTION].description
            }
            unitTransactions={unitTransactions}
            saleTransactionFilterOptions={saleTransactionFilterOptions}
          />
        ),
        hidden: isEmpty(unitTransactions.items),
        title: t(projectSectionConfig[Section.SALES_TRANSACTION].title),
        targetId: Section.SALES_TRANSACTION,
      },
    ].filter((item) => !item.hidden);
  }, [
    project,
    floorPlanFilterOptions,
    floorPlansPagination,
    unitTransactions,
    saleTransactionFilterOptions,
    t,
  ]);

  const [activeTab, setActiveTab] = useState<IUnitsTab>(tabsList[0]);

  const isMobile = useMediaQuery("lg");

  return (
    <LDPSection
      styleRoot={{
        ...styleRoot,
      }}
      className={cn(" w-full overflow-hidden py-20 lg:py-12", className)}
      {...rest}
    >
      <TabGroup
        className={cn("flex flex-col", "lg:px-0")}
        onChange={(value) => {
          setActiveTab(tabsList[value]);
        }}
      >
        <div className="lg:items-star ldp flex flex-row items-center gap-6 px-0 lg:flex-col ">
          <Typo
            className="min-w-[400px] font-bold !text-[var(--color-text)] lg:w-full lg:text-header-32"
            variant={ETypoVariant.HEADER_56}
            tag={ETypoTag.H3}
          >
            Unit Analysis
          </Typo>
          <TabList className="scroll-x  flex-1flex-row flex w-full flex-nowrap items-start  justify-start gap-x-6 overflow-x-auto">
            {tabsList.map((tab, idx) => (
              <Tab
                key={idx}
                hidden={tab.hidden}
                className={cn(" outline-none  ring-0")}
              >
                {({ selected }) => (
                  <Button variant="transparent" className="rounded-full p-0">
                    <Typo
                      className={cn(
                        "whitespace-nowrap border-b-[3px] border-transparent py-3  font-normal  capitalize  !text-[var(--color-text)] opacity-25 ",
                        selected &&
                          "border-[var(--line-active-tab)] opacity-100"
                      )}
                      tag={ETypoTag.SPAN}
                      variant={ETypoVariant.HEADER_18}
                    >
                      {tab.title}
                    </Typo>
                  </Button>
                )}
              </Tab>
            ))}
          </TabList>
        </div>
        <TabPanels
          className={cn(
            "px-0 lg:-mx-6 [&_.unit-analysis-title>*]:!text-[var(--color-text)] [&_tbody>tr]:bg-[var(--bg-section)] [&_tbody>tr]:text-[var(--color-table-text)] [&_thead>tr]:bg-[var(--bg-section)]  [&_thead_th]:uppercase [&_thead_th]:text-[var(--color-table-head-text)] [&_thead_th]:opacity-50",
            !isMobile && "ldp-container"
          )}
        >
          {tabsList.map((tab, idx) => {
            return (
              <TabPanel id={tab.targetId} key={tab.targetId}>
                {tab.TabPanel}
              </TabPanel>
            );
          })}
        </TabPanels>
      </TabGroup>
    </LDPSection>
  );
}
