import CleanedContent from "../atoms/CleanedContent";

import { cn } from "~/utilities/cn";

export interface LDPTitleProps {
  title: string;
  description?: string;
  className?: string;
}

export default function LDPTitle({
  title,
  description,
  className,
}: LDPTitleProps) {
  return (
    <div className={cn("space-y-2", className)}>
      <CleanedContent
        className="ldp-title h-auto  !text-header-56 font-bold lg:!text-header-32"
        htmlContent={title}
      />

      {description && (
        <CleanedContent
          className="ldp-title-description !text-sub-title-16"
          htmlContent={description}
        />
      )}
    </div>
  );
}
