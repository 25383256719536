import { useMemo, useRef, useState } from "react";
import { Autoplay, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import LDPSection, { LDPSectionProps } from "../../LDPSection";

import CleanedContent from "~/components/atoms/CleanedContent";
import { Icon } from "~/components/atoms/Icons";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import { EIcon } from "~/utilities/enums/Icons";
import { EMedia } from "~/utilities/enums/Media";
import { ETypoVariant } from "~/utilities/enums/Typo";
export interface LDPMastheadGalleryProps extends LDPSectionProps {
  project: IProject;
  dynamicHtml?: string;
  thumbnailImg: IMediaImageUrls;
  titleHtml?: string;
  subTitleHtml?: string;
}

export default function LDPMastheadGallery({
  project,
  titleHtml,
  subTitleHtml,
  dynamicHtml,
  thumbnailImg,
  styleRoot,
  ...rest
}: LDPMastheadGalleryProps) {
  const [currentSlide, setCurrentSlide] = useState(1); // Tracks the active slide

  const swiperRef = useRef<any>(null);

  const carouselImage = useMemo(() => {
    return project.medias.filter((item) => item.type === EMedia.IMAGE);
  }, [project.medias]);

  const totalSlides = carouselImage.length; // Total number of items (adjust as needed)

  return (
    <LDPSection
      className={cn(
        "grid grid-cols-2 gap-x-[130px] pb-12 pt-6 lg:grid-cols-1 lg:gap-x-0 lg:gap-y-6 lg:py-8 [&_*]:!text-[var(--main-color)]"
      )}
      styleRoot={{
        ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPMastheadGallery],
        ...styleRoot,
      }}
      {...rest}
    >
      <div className="flex flex-col justify-between gap-[5.375rem] pt-12 lg:pt-0">
        <div className="space-y-12 lg:space-y-6">
          <CleanedContent
            htmlContent={titleHtml || project?.name}
            className="h-auto w-full  !text-[68px] lg:text-center lg:!text-[40px]"
          />
          <div className="space-y-6 lg:space-y-3">
            <Typo
              className="text-start lg:text-center lg:text-header-20"
              tag="h4"
              variant={ETypoVariant.HEADER_24}
            >
              <CleanedContent
                htmlContent={subTitleHtml || project?.location?.name}
                className="h-full w-full  lg:text-center"
              />
            </Typo>
            <div className="flex flex-col   gap-y-6">
              <CleanedContent
                htmlContent={dynamicHtml || project.description}
                className="h-full w-full text-body-title-16 lg:text-center"
              />
            </div>
          </div>
        </div>

        <div className="block overflow-hidden lg:hidden">
          <ImageResponsive
            className="h-[360px] w-full rounded-b-[360px] object-cover  object-center"
            pictureClassName="block"
            imageData={thumbnailImg}
          />
        </div>
      </div>

      <div className="flex w-full flex-col gap-6">
        <div className="relative z-10">
          <Swiper
            className="relative z-10 w-full  overflow-hidden   rounded-t-[360px]"
            slidesPerView={1}
            modules={[EffectFade, Autoplay]}
            effect="fade"
            autoplay={{
              delay: 5000,
            }}
            ref={swiperRef}
            loop
            onSlideChange={(swiper) => {
              setCurrentSlide(swiper.realIndex + 1);
            }}
          >
            {carouselImage.map((img, idx) => (
              <SwiperSlide
                className="h-[711px] w-full overflow-hidden  lg:h-[500px] sm:h-[398px]"
                key={`${ESectionLDP.LDPMastheadGallery}-${img.id}-${idx}`}
              >
                <ImageResponsive
                  className="h-full w-full  object-cover  object-top"
                  pictureClassName="block"
                  imageData={img}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          {!!project?.medias?.length && (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  swiperRef.current?.swiper?.slidePrev();
                }}
                className={cn(
                  "absolute left-0 top-1/2  z-50 hidden  h-10 w-10   -translate-x-1/3 -translate-y-1/2 items-center justify-center rounded-full bg-[#CBE8D2] shadow-md transition-transform duration-200 lg:flex ",
                  {
                    "cursor-not-allowed opacity-50": currentSlide - 1 === 0,
                    "hover:scale-105 active:scale-95": currentSlide - 1 !== 0,
                  }
                )}
                disabled={currentSlide - 1 === 0}
              >
                <Icon
                  TypeIcon={EIcon.ArrowLeftIcon}
                  height={40}
                  width={40}
                  className="text-color"
                />
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  swiperRef.current?.swiper?.slideNext();
                }}
                className={cn(
                  "absolute right-0 top-1/2 z-50 hidden   h-10  w-10   -translate-y-1/2 translate-x-1/3   items-center justify-center rounded-full   bg-[#0F393A] shadow-md transition-transform duration-200 lg:flex ",
                  {
                    "cursor-not-allowed opacity-50":
                      currentSlide === totalSlides,
                    "hover:scale-105 active:scale-95":
                      currentSlide === totalSlides,
                  }
                )}
                disabled={currentSlide === totalSlides}
              >
                <Icon
                  TypeIcon={EIcon.ArrowRightIcon}
                  height={40}
                  width={40}
                  className="text-white"
                />
              </button>
            </>
          )}
        </div>

        <div className="space-y-3">
          <div className="flex items-center justify-between px-1 lg:hidden">
            <Typo
              tag="p"
              className="font-bold"
              variant={ETypoVariant.HEADER_18}
            >
              Explore more
            </Typo>

            <div className="flex items-center gap-x-3">
              {!!project?.medias?.length && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      swiperRef.current?.swiper?.slidePrev();
                    }}
                    className={cn(
                      "  z-50 flex  h-10 w-10 items-center justify-center rounded-full border border-[var(--main-color)] shadow-md transition-transform duration-200 ",
                      {
                        "cursor-not-allowed opacity-50": currentSlide - 1 === 0,
                        "hover:scale-105 active:scale-95":
                          currentSlide - 1 !== 0,
                      }
                    )}
                    disabled={currentSlide - 1 === 0}
                  >
                    <Icon
                      TypeIcon={EIcon.ArrowLeftIcon}
                      height={40}
                      width={40}
                      className="text-color"
                    />
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      swiperRef.current?.swiper?.slideNext();
                    }}
                    className={cn(
                      "z-50 flex  h-10 w-10 items-center justify-center rounded-full border border-[var(--main-color)] shadow-md transition-transform duration-200 ",
                      {
                        "cursor-not-allowed opacity-50":
                          currentSlide === totalSlides,
                        "hover:scale-105 active:scale-95":
                          currentSlide === totalSlides,
                      }
                    )}
                    disabled={currentSlide === totalSlides}
                  >
                    <Icon
                      TypeIcon={EIcon.ArrowRightIcon}
                      height={40}
                      width={40}
                      className="text-color"
                    />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="relative mt-4">
            <div className="relative h-1 rounded-full ">
              {/* Active Progress */}
              <div className="absolute left-0 top-0 z-10 h-1 w-full rounded-full bg-[var(--main-color)] opacity-20  transition-all"></div>
              <div
                className="absolute left-0 top-0 z-10 h-1 rounded-full bg-[var(--main-color)] transition-all"
                style={{
                  width: `${(currentSlide / totalSlides) * 100}%`, // Dynamically adjust width
                }}
              ></div>
            </div>

            <div className="mt-3 flex items-center justify-between text-sm text-[var(--main-color)]">
              <span>
                {currentSlide}/{totalSlides}
              </span>
            </div>
          </div>
        </div>
      </div>
    </LDPSection>
  );
}
