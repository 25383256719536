import SaleActionsBar from "~/components/molecules/SaleActionsBar";
import { cn } from "~/utilities/cn";

export interface ContactSalesStickyProps {
  salePhoneNumber: string;
  saleContent?: string;
  onRegisterClick?: () => void;
  className?: string;
}

export default function ContactSalesSticky(props: ContactSalesStickyProps) {
  const {
    salePhoneNumber,
    saleContent = "Need help? Contact us",
    onRegisterClick,
    className,
  } = props;

  return (
    <div
      className={cn(
        "sticky bottom-0 left-0 right-0 z-50 hidden w-full items-center justify-between gap-3 bg-footerBackground py-3   shadow-inner transition-all duration-300 ease-in-out lg:flex",
        className
      )}
    >
      <SaleActionsBar
        salePhoneNumber={salePhoneNumber}
        saleContent={saleContent}
        onRegisterClick={onRegisterClick}
        className="ldp-container"
        theme="dark"
      />
    </div>
  );
}
