import LDPContactSaleForm from "../../LDPConTactSaleForm";
import LDPSection, { LDPSectionProps } from "../../LDPSection";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import { IMediaImageUrls } from "~/entities/media";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";

export interface LDPContactSalesProps extends LDPSectionProps {
  contactSaleImage: IMediaImageUrls;
}

export default function LDPContactSales({
  styleRoot,
  contactSaleImage,
  id,
}: LDPContactSalesProps) {
  return (
    <LDPSection
      styleRoot={{
        ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPContactSales],
        ...styleRoot,
      }}
      className="overflow-hidden pl-0 lg:px-4"
      id={id}
    >
      <div className="grid grid-cols-[calc(50%-6rem)_1fr] gap-x-[130px] lg:grid-cols-1">
        <div className="relative block !overflow-visible lg:hidden">
          <ImageResponsive
            className="circle absolute bottom-0 left-0 w-full  min-w-[1237px]  -translate-x-1/4 translate-y-1/2  object-cover object-center"
            pictureClassName="block"
            imageData={contactSaleImage}
            alt="contact sale image"
          />
        </div>

        <div className="relative z-10 py-24 lg:py-12">
          <LDPContactSaleForm className="rounded-3xl lg:rounded-xl" />
        </div>
      </div>
    </LDPSection>
  );
}
