import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";

import Media from "../Media";

import { TMedia } from "~/entities/media";
import { cn } from "~/utilities/cn";

interface SelectedMediaProps {
  selectedMedia: TMedia[];
  onReSort?: (newSort: TMedia[]) => void;
  hoveringId?: string | null;
  onHover?: (id: string) => void;
  onLeave?: () => void;

  className?: string;
}

export default function SelectedMedia({
  selectedMedia,
  onReSort,
  onHover,
  onLeave,
  hoveringId,
  className,
}: SelectedMediaProps) {
  const [items, setItems] = useState(selectedMedia);
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const [overId, setOverId] = useState<string | null>(null);
  const containerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    setItems(selectedMedia);
  }, [selectedMedia]);

  // Start dragging: Set dragging item ID
  const handleDragStart = (id: string) => {
    setDraggingId(id);
  };

  // Track the item that is currently being hovered over
  const handleDragEnter = (id: string) => {
    if (id !== draggingId) {
      setOverId(id);
    }
  };

  // Reorder function for moving items in the list
  const reorderList = (items: TMedia[], fromIndex: number, toIndex: number) => {
    const updatedItems = [...items];
    const movedItem = updatedItems.splice(fromIndex, 1)[0];
    updatedItems.splice(toIndex, 0, movedItem);
    return updatedItems;
  };

  // Handle drag end: Apply new order
  const handleDragEnd = useCallback(() => {
    if (!draggingId || !overId || draggingId === overId) {
      setDraggingId(null);
      setOverId(null);
      return;
    }

    const fromIndex = items.findIndex((item) => item.id === draggingId);
    const toIndex = items.findIndex((item) => item.id === overId);

    if (fromIndex !== -1 && toIndex !== -1 && fromIndex !== toIndex) {
      const newItems = reorderList(items, fromIndex, toIndex);
      setItems(newItems);
      onReSort?.(newItems);
    }

    setDraggingId(null);
    setOverId(null);
  }, [draggingId, overId, items, onReSort]);

  // Auto-scroll when dragging near the edges
  const handleDragMove = (event: any) => {
    const container = containerRef.current;
    if (!container) return;

    const { clientY } = event;
    const { top, bottom } = container.getBoundingClientRect();
    const scrollSpeed = 100;

    if (clientY < top + 50) {
      container.scrollBy({ top: -scrollSpeed, behavior: "smooth" });
    } else if (clientY > bottom - 50) {
      container.scrollBy({ top: scrollSpeed, behavior: "smooth" });
    }
  };

  // Ensure drag end fires even if dragged out of bounds
  useEffect(() => {
    const handlePointerUp = () => {
      handleDragEnd();
    };

    window.addEventListener("pointerup", handlePointerUp);
    return () => window.removeEventListener("pointerup", handlePointerUp);
  }, [handleDragEnd]);

  return (
    <div className={cn("h-full w-full  rounded-lg bg-white", className)}>
      <h3 className="title mb-2 text-lg font-semibold">Selected Media</h3>
      <motion.ul
        className="listWrapper scroll flex h-full max-h-[400px] flex-col gap-y-2 overflow-auto p-2"
        ref={containerRef}
      >
        {items &&
          items?.length > 0 &&
          items?.map((item, idx) => (
            <motion.li
              key={item?.id}
              data-selected-id={item?.id}
              layout
              draggable
              onDrag={handleDragMove}
              onDragStart={() => handleDragStart(item?.id)}
              onDragEnter={() => handleDragEnter(item?.id)}
              onDragEnd={handleDragEnd}
              className={cn(
                "listItem flex cursor-pointer items-center rounded-md border bg-gray-100 p-2 hover:bg-gray-200",
                draggingId === item?.id ? "scale-95 opacity-50" : "opacity-100",
                overId === item?.id && draggingId !== overId ? "mt-8" : "mt-0",
                hoveringId === item?.id && "ring-2 ring-amber-500"
              )}
              whileDrag={{ scale: 1.1 }}
              onMouseEnter={() => {
                onHover?.(item?.id);
              }}
              onMouseLeave={() => {
                onLeave?.();
              }}
            >
              <div className="relative flex w-full items-baseline gap-x-2">
                <p className="flex h-6 w-6 flex-shrink-0 items-center justify-center  rounded-full bg-color text-xs font-semibold text-white">
                  <span>{idx + 1}</span>
                </p>
                <span className="w-full flex-1 truncate text-sm capitalize text-color">
                  [{item?.type}] {item?.id?.split("-")[0]}
                </span>

                <div className="pointer-events-none absolute right-0 top-0 aspect-[16/9] h-auto w-full max-w-[40px] overflow-hidden">
                  <Media
                    className="h-full w-full"
                    pictureClassName="block"
                    media={item}
                  />
                </div>
              </div>
            </motion.li>
          ))}
      </motion.ul>
    </div>
  );
}
