import * as React from "react";
import { cn } from "~/utilities/cn";

export interface LDPSectionProps {
  styleRoot?: {
    // @ts-ignore
    "--bg-section"?: string;
    [key: string]: string;
  };

  id?: string;
  className?: string;
  classNameSection?: string;
  children?: React.ReactNode;
}

function LDPSection(
  { styleRoot, className, classNameSection, id, children }: LDPSectionProps,
  ref: React.Ref<HTMLDivElement>
) {
  return (
    <section
      // @ts-ignore
      style={{
        "--bg-section": "transparent",
        ...styleRoot,
      }}
      data-type="ldp-section"
      className={cn("w-full bg-[var(--bg-section)]", classNameSection)}
      ref={ref}
      id={id}
    >
      <div className={cn("ldp-container", className)}>{children}</div>
    </section>
  );
}

LDPSection.displayName = "LDPSection";
export default React.forwardRef(LDPSection);
