import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useLocation, useMatches } from "@remix-run/react";
import isEmpty from "lodash/isEmpty";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ContactUsButton from "../ContactUsButton";
import { IContentSection } from "../ContentsNavigator/types";

import IconChevronDown from "~/components/atoms/Icons/IconChevronDown";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import Typo from "~/components/atoms/Typo";
import { IMediaImageUrls } from "~/entities/media";
import { IProject } from "~/entities/project";
import { useScrollTop } from "~/hooks/use-scroll-top";
import useStickyObserver from "~/hooks/use-sticky-observer";
import { ILink } from "~/types";
import { cn } from "~/utilities/cn";
import {
  SCROLL_OFFSET,
  SCROLL_OFFSET_MB,
} from "~/utilities/constants/projectSections";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Section } from "~/utilities/enums/ProjectSections";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface HeaderProps {
  logo?: IMediaImageUrls;
  topButton?: ILink;
  className?: string;
  project: IProject;
  sections: IContentSection[];
  defaultSection: IContentSection;
}

export type Ref = HTMLDivElement;

const HeaderLDP = forwardRef<Ref, HeaderProps>(
  ({ logo, topButton, className, project, sections, defaultSection }, ref) => {
    const scrolled = useScrollTop();
    const { t } = useTranslation();
    const matches = useMatches();
    const isHomePage = matches.some((match) => match.pathname === Slug.HOME);
    const headerRef = useRef<HTMLDivElement>(null);

    const isSticky = useStickyObserver({
      ref: headerRef,
      threshold: 1,
    });

    const location = useLocation();
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 992px)" });
    const [selectedSection, setSelectedSection] = useState(
      defaultSection || sections[0]
    );

    const [currentSection, setCurrentSection] = useState<string>(
      location?.hash?.replace("#", "") || defaultSection?.targetId
    );

    const scrollToView = useCallback(
      (targetId: string, offset: number = SCROLL_OFFSET) => {
        // TODO: Optimize this function
        // Make sure all element wrapper tag name is section
        let sectionEl = document.getElementById(targetId);

        if (sectionEl && sectionEl?.tagName.toLocaleLowerCase() !== "section") {
          // For group  section ids, example: landing page table units
          sectionEl = sectionEl?.closest("section");
        }

        if (sectionEl) {
          const y =
            sectionEl.getBoundingClientRect().top + window.scrollY + offset;

          // Update the URL hash without scrolling again
          window.history.pushState(null, "", `#${targetId}`);
          // if (y === window.scrollY + offset) return; // return if window not change

          window.scrollTo({ top: y, behavior: "smooth" });
        }

        setCurrentSection(targetId);
      },
      []
    );

    useEffect(() => {
      if (location.hash) {
        const timeoutId = setTimeout(() => {
          scrollToView(
            currentSection,
            isTabletOrMobile ? SCROLL_OFFSET_MB : SCROLL_OFFSET
          );
        }, 1000);

        return () => clearTimeout(timeoutId); // Cleanup to avoid memory leaks
      }
    }, [location.hash, currentSection, isTabletOrMobile, scrollToView]);

    // if (!isHomePage && !isProjectPage && !isSectionsPage) return <></>;

    useEffect(() => {
      if (headerRef.current && ref) {
        ref = headerRef;
      }
    }, [headerRef.current]);

    return (
      <>
        {isHomePage && (
          <header
            ref={headerRef}
            data-name="Header"
            className={cn(
              `sticky top-[-1px] z-100 block w-screen overflow-hidden bg-transparent py-4 transition-all duration-200 lg:hidden`,
              isSticky &&
                "w-full border-b bg-backgroundPageProject shadow-header",

              className
            )}
          >
            <div className="ldp flex items-center justify-between gap-x-12">
              {logo ? (
                <ImageResponsive
                  imageData={logo}
                  alt="Brand Logo"
                  className="h-[34px] w-auto origin-left object-cover"
                  zoom={1.3}
                  displayDisclaimer={false}
                />
              ) : (
                <Typo
                  tag={ETypoTag.H1}
                  variant={ETypoVariant.HEADER_32}
                  color={ETypoColor.HEADER_TEXT}
                  className="truncate text-center font-bold"
                >
                  {project?.name}
                </Typo>
              )}

              <div className="flex items-center gap-x-12">
                {!isEmpty(sections) &&
                  sections.slice(0, 3).map(({ targetId, label }) => {
                    const actived = currentSection === targetId;

                    return (
                      <button
                        key={targetId}
                        onClick={() => {
                          scrollToView(
                            targetId,
                            isTabletOrMobile ? SCROLL_OFFSET_MB : SCROLL_OFFSET
                          );
                        }}
                      >
                        <Typo
                          tag={ETypoTag.P}
                          variant={ETypoVariant.HEADER_16}
                          color={ETypoColor.HEADER_TEXT}
                          className={cn(
                            "w-max text-center transition-all duration-200 hover:text-headerTextHover",
                            actived ? "font-bold text-headerTextActive" : ""
                          )}
                        >
                          {t(label)}
                        </Typo>
                      </button>
                    );
                  })}
                <Listbox value={selectedSection} onChange={setSelectedSection}>
                  <ListboxButton className="flex items-center justify-center gap-3">
                    <Typo
                      tag={ETypoTag.SPAN}
                      variant={ETypoVariant.HEADER_16}
                      color={ETypoColor.HEADER_TEXT}
                      className="text-center transition-all duration-200 hover:text-headerTextHover"
                    >
                      {t("other")}
                    </Typo>
                    <IconChevronDown className="text-color" />
                  </ListboxButton>
                  <ListboxOptions
                    anchor="bottom end"
                    className="scroll z-100 w-max min-w-[calc(var(--button-width)+80px)] rounded-xl bg-white pt-1.5 shadow-dropdownSelect focus:outline-none lg:w-[var(--button-width)]"
                  >
                    {sections.slice(3).map((section) => (
                      <ListboxOption
                        key={section.targetId}
                        value={section}
                        className="group flex cursor-pointer select-none items-center p-3 pr-7 data-[focus]:bg-cornflowerBlue lg:p-2"
                        onClick={() => {
                          scrollToView(
                            section.targetId,
                            isTabletOrMobile ? SCROLL_OFFSET_MB : SCROLL_OFFSET
                          );
                        }}
                      >
                        {({ selected }) => (
                          <button>
                            <Typo
                              tag={ETypoTag.SPAN}
                              variant={ETypoVariant.HEADER_16}
                              color={ETypoColor.HEADER_TEXT}
                              className={cn(
                                "line-clamp-1 text-left transition-all duration-200 hover:text-headerTextHover",
                                selected
                                  ? "font-bold text-headerTextActive"
                                  : ""
                              )}
                            >
                              {t(section.label)}
                            </Typo>
                          </button>
                        )}
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </Listbox>
                <ContactUsButton
                  onClick={() => {
                    scrollToView(Section.CONTACT_SALES, -60);
                  }}
                  label="Book viewing"
                />
              </div>
            </div>
          </header>
        )}
      </>
    );
  }
);

HeaderLDP.displayName = "HeaderLDP";

export default HeaderLDP;
