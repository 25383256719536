import LDPSection, { LDPSectionProps } from "../../LDPSection";
import LDPTitle from "../../LDPTitle";

import CleanedContent from "~/components/atoms/CleanedContent";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import { IMediaImageUrls } from "~/entities/media";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O2,
} from "~/utilities/config/landingPageConfig";

export interface LDPMarketingProps extends LDPSectionProps {
  imagesData: IMediaImageUrls[];
  title: string;
  descriptionHtml: string; // html content
}

export default function LDPMarketing({
  styleRoot = VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPMarketing],
  imagesData,
  title,
  descriptionHtml,
}: LDPMarketingProps) {
  const [thumbnail, ...images] = imagesData;
  return (
    <LDPSection
      className="flex flex-col gap-y-20 rounded-tr-[560px] bg-[var(--bg-inner)] py-[6.125rem] lg:gap-y-8 lg:rounded-tr-[240px] lg:py-12"
      styleRoot={styleRoot}
    >
      <div className="flex flex-row gap-6 lg:flex-col-reverse">
        <div className="mr-20 flex flex-1 flex-col gap-8 lg:mr-0">
          <LDPTitle
            title={title}
            className="text-[var(--color-text)] [&_.ldp-title]:!text-[80px]/[1.2] lg:[&_.ldp-title]:!text-[40px]/[48px]"
          />
          <hr className="h-0.5 bg-[var(--color-text)] text-[var(--color-text)]" />
          <CleanedContent
            className="text-[var(--color-text)] opacity-75"
            htmlContent={descriptionHtml}
          />
        </div>

        <div className="flex-1">
          <ImageResponsive
            imageData={thumbnail}
            className="relative z-10  h-[480px]  w-full rounded-bl-[160px]  object-cover  object-center lg:h-[280px] lg:max-w-full  lg:rounded-bl-[100px]"
            pictureClassName="block"
          />
        </div>
      </div>

      {images.length > 0 && (
        <div className="scroll-x flex flex-row gap-6 overflow-y-hidden lg:-mx-4 lg:gap-3">
          {images.map((img) => (
            <div className="flex-1" key={img.id}>
              <ImageResponsive
                imageData={img}
                className="w-ful relative z-10 h-full max-h-[225px] min-w-[400px]  object-cover   object-center  lg:max-h-[135px] lg:min-w-[64vw] lg:max-w-full"
                pictureClassName="block"
              />
            </div>
          ))}
        </div>
      )}
    </LDPSection>
  );
}
