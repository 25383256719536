import { useMemo } from "react";

import LDPSection, { LDPSectionProps } from "../../LDPSection";
import LDPTitle from "../../LDPTitle";

import CleanedContent from "~/components/atoms/CleanedContent";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import { IMediaImageUrls } from "~/entities/media";
import {
  ESectionLDP,
  VARIABLE_COLORS_SECTIONS_O3,
} from "~/utilities/config/landingPageConfig";
import getRandomItem from "~/utilities/helpers/randomItem";

export interface LDPMarketingProps extends LDPSectionProps {
  imagesData: IMediaImageUrls[];
  title: string;
  descriptionHtml: string; // html content;
  highlightThumbnail?: IMediaImageUrls;
}

export default function LDPMarketing({
  styleRoot,
  imagesData,
  title,
  descriptionHtml,
  highlightThumbnail,
}: LDPMarketingProps) {
  const thumbnailImage = useMemo(() => {
    return highlightThumbnail || getRandomItem(imagesData);
  }, [highlightThumbnail?.id, imagesData]);
  return (
    <LDPSection
      className="flex flex-col gap-y-12 py-24 lg:py-12  [&_*]:!text-[--color-text]"
      styleRoot={{
        ...VARIABLE_COLORS_SECTIONS_O3[ESectionLDP.LDPMarketing],
        ...styleRoot,
      }}
    >
      <div className="flex flex-row gap-6  lg:flex-col lg:gap-12">
        <div className="mr-20 flex flex-1 flex-col gap-12 lg:mr-0 lg:gap-6">
          <LDPTitle
            title={title}
            className="[&_.ldp-title]:!text-[80px]/[1.2] lg:[&_.ldp-title]:!text-[40px]/[48px]"
          />
          <hr className="h-px w-full bg-[var(--color-text)] text-[var(--color-text)]" />
          <CleanedContent
            className="text-color opacity-75"
            htmlContent={descriptionHtml}
          />
        </div>

        <div className="flex-1">
          <ImageResponsive
            imageData={thumbnailImage}
            className="relative z-10 h-[586px] w-full rounded-b-[360px]  object-cover object-center  lg:aspect-[343/329]  lg:h-auto  lg:max-w-full"
            pictureClassName="block"
          />
        </div>
      </div>

      {imagesData.length > 0 && (
        <div className="scroll-x  -mx-4 flex flex-row gap-6 overflow-scroll  lg:gap-4">
          {imagesData.map((img, idx) => (
            <div
              key={`${ESectionLDP.LDPMarketing}-${img.id}-${idx}`}
              className="flex-1  lg:first:pl-4  lg:last:pr-4"
            >
              <ImageResponsive
                imageData={img}
                className="circle relative z-10 h-auto w-full min-w-[294px] object-cover  object-center lg:h-auto lg:min-w-[253px] lg:max-w-full"
                pictureClassName="block"
              />
            </div>
          ))}
        </div>
      )}
    </LDPSection>
  );
}
