import LDPContactSaleForm from "../../LDPConTactSaleForm";
import LDPSection, { LDPSectionProps } from "../../LDPSection";

import ImageResponsive from "~/components/atoms/ImageResponsive";
import { IMediaImageUrls } from "~/entities/media";

export interface LDPContactSalesProps extends LDPSectionProps {
  contactSaleImage: IMediaImageUrls;
}

export default function LDPContactSales({
  styleRoot = {
    "--bg-section": "#FFFCF5",
    "--color-contact-sales-text": "var(--color-color)",
    "--color-contact-sales-checkbox-border": "var(--color-color)",
    "--color-enquiry-text": "var(--color-color)",
    "--bg-contact-form": "#FFFCF5",
    "--bg-wrapper": "#D2DFD5",
  },
  contactSaleImage,
  id,
}: LDPContactSalesProps) {
  return (
    <LDPSection
      id={id}
      styleRoot={styleRoot}
      className="max-w-[1440px] bg-[var(--bg-wrapper)] p-24 lg:px-4 lg:py-12"
    >
      <div className="grid grid-cols-[calc(50%-6rem)_1fr]  gap-x-[130px] lg:grid-cols-1">
        <div className="-my-24 -ml-24 overflow-hidden rounded-tr-[180px] lg:m-0 lg:rounded-tr-[100px]">
          <ImageResponsive
            className="h-full w-full object-cover object-center lg:h-[240px]"
            pictureClassName="block"
            imageData={contactSaleImage}
            alt="contact sale image"
          />
        </div>

        <LDPContactSaleForm />
      </div>
    </LDPSection>
  );
}
