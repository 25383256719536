const colors = {
  /*Header*/
  headerBackground: "var(--color-header-background, #F1F0EE)",
  headerText: "var(--color-header-text, #172C37)",
  headerTextHover: "var(--color-header-text-hover, #D03243)",
  headerTextActive: "var(--color-header-text-active, #D03243)",
  headerDivider: "var(--color-header-divider, rgba(23, 44, 55, 0.25))",
  /*Search & Filter bar*/
  filterBarBackground: "var(--color-filter-bar-background, #FFFFFF)",
  filterBarDrawerBackground:
    "var(--color-filter-bar-drawer-background,#F1F0EE)",
  filterBarText: "var(--color-filter-bar-text, #172C37)",
  filterBarPlaceholder:
    "var(--color-filter-bar-placeholder, rgba(23, 44, 55, 0.25))",
  searchBarBorder: "var(--color-search-bar-border, #172C37)",
  searchBarBackground: "var(--color-search-bar-background, #FFFFFF)",
  searchBarText: "var(--color-filter-bar-text, #172C37)",
  searchBarPlaceholder:
    "var(--color-filter-bar-placeholder, rgba(23, 44, 55, 0.25))",
  buttonSearchBackground: "var(--color-button-search-background, #172C37)",
  buttonSearchText: "var(--color-button-search-text, #FFFFFF)",
  /*Highlight*/
  highlightBackground: "var(--color-highlight-background, #FFFFFF)",
  highlightText: "var(--color-highlight-text, #172C37)",
  /*Footer*/
  footerBackground: "var(--color-footer-background, #172C37)",
  footerText: "var(--color-footer-text, #FFFFFF)",
  footerBorder: "var(--color-footer-border, #FFFFFF)",
  /*ModalProjectDetail*/
  modalProjectDetailBackground:
    "var(--color-modal-project-detail-background, #FFFFFF)",
  modalProjectDetailDivide:
    "var(--color-modal-project-detail-divide, rgba(23, 44, 55, 0.25))",
  modalProjectDetailText:
    "var(--color-modal-project-detail-text, rgba(23, 44, 55, 1))",
  modalProjectDetailSoldOut:
    "var(--color-modal-project-detail-sold-out, #D03243)",
  modalProjectDetailAvailable:
    "var(--color-modal-project-detail-available, #099628)",
  modalProjectDetailShowFlatAppointmentBackground:
    "var(--color-modal-project-detail-show-flat-appointment-background, rgba(23, 44, 55, 1))",
  modalProjectDetailShowFlatAppointmentBorder:
    "var(--color-modal-project-detail-show-flat-appointment-border, rgba(23, 44, 55, 1))",
  modalProjectDetailShowFlatAppointmentText:
    "var(--color-modal-project-detail-show-flat-appointment-text, rgba(255, 255, 255, 1))",
  modalProjectDetailSoldOutButtonBackground:
    "var(--color-modal-project-detail-sold-out-button-background, #099628)",
  modalProjectDetailSoldOutButtonBorder:
    "var(--color-modal-project-detail-sold-out-button-border, #099628)",
  modalProjectDetailSoldOutButtonText:
    "var(--color-modal-project-detail-sold-out-button-text, rgba(255, 255, 255, 1))",
  /*Contact Sales*/
  contactSalesBackground: "var(--color-contact-sales-background, #172C37)",
  contactSalesHeader: "var(--color-contact-sales-header, #FFFFFF)",
  contactSalesAgent: "var(--color-contact-sales-agent, #172C37)",
  contactSalesAgentRole:
    "var(--color-contact-sales-agent-role, rgba(23, 44, 55, 0.5))",
  contactSalesInputBorder:
    "var(--color-contact-sales-input-border, rgba(255, 255, 255, 0.25))",
  contactSalesInputBackground:
    "var(--color-contact-sales-input-background, #172C37)",
  contactSalesDivider:
    "var(--color-contact-sales-divider, rgba(255, 255, 255, 0.25))",
  contactSalesText: "var(--color-contact-sales-text, #FFFFFF)",
  contactSalesSubmitText: "var(--color-contact-sales-submit-text, #FFFFFF)",
  contactSalesCheckboxBorder:
    "var(--color-contact-sales-checkbox-border, rgba(255, 255, 255, .5))",
  contactSalesSubmitBackground:
    "var(--color-contact-sales-submit-background, #099628)",

  /*Enquiry*/
  enquiryBackground: "var(--color-enquiry-background, #F0F1EE)",
  enquiryHeader: "var(--color-enquiry-header, #172C37)",
  enquiryAgent: "var(--color-enquiry-agent, #172C37)",
  enquiryAgentRole: "var(--color-enquiry-agent-role, rgba(23, 44, 55, 0.5))",
  enquiryInputBorder:
    "var(--color-enquiry-input-border, rgba(23, 44, 55, 0.25))",
  enquiryInputBackground:
    "var(--color-enquiry-input-background, rgba(255, 255, 255, 1))",
  enquiryDivider: "var(--color-enquiry-divider, rgba(0, 0, 0, 0.25))",
  enquiryText: "var(--color-enquiry-text, #172C37)",
  enquirySubmitText: "var(--color-enquiry-submit-text, #FFFFFF)",
  enquirySubmitBackground: "var(--color-enquiry-submit-background, #172C37)",
  enquiryCheckboxBorder:
    "var(--color-enquiry-checkbox-border, rgba(23, 44, 55, 0.5))",
  enquiryInputPlaceholder:
    "var(--color-enquiry-input-placeholder, rgba(23, 44, 55, 0.5))",

  // Filter Sidebar Range
  filterSidebarRangeInputBackground:
    "var(--color-filter-sidebar-range-input-background, #FFFFFF)",
  filterSidebarRangeInputText:
    "var(--color-filter-sidebar-range-input-text, rgba(23, 44, 55, 1))",
  filterSidebarRangeInputBorder:
    "var(--color-filter-sidebar-range-input-border, #172C37)",

  // Form Contact us
  formContactUsBackground: "var(--color-form-contact-us-background, #F0F1EE)",
  formContactUsHeader: "var(--color-form-contact-us-header, #172C37)",
  formContactUsInputBorder:
    "var(--color-form-contact-us-input-border, rgba(23, 44, 55, 0.25))",
  formContactUsInputBackground:
    "var(--color-form-contact-us-input-background, rgba(255, 255, 255, 1))",
  formContactUsText: "var(--color-form-contact-us-text, #172C37)",
  formContactUsSubmitText: "var(--color-form-contact-us-submit-text, #FFFFFF)",
  formContactUsSubmitBackground:
    "var(--color-form-contact-us-submit-background, #172C37)",
  formContactUsCheckboxBorder:
    "var(--colo-form-contact-us-checkbox-border, #172C3740)",

  formContactUsFooterLabel:
    "var(--colo-form-contact-us-footer-label-border, #172C37)",

  // Project - Card go to Enquiry
  projectCardGoToEnquiryBackground:
    "var(--color-project-card-go-to-enquiry-background, #172C37)",
  projectCardGoToEnquiryText:
    "var(--color-project-card-go-to-enquiry-text, #FFFFFF)",
  projectCardGoToEnquiryButton:
    "var(--color-project-card-go-to-enquiry-button, #D03243)",
  projectCardGoToEnquiryButtonText:
    "var(--color-project-card-go-to-enquiry-button-text, #FFFFFF)",
  projectCardGoToEnquiryButtonHover:
    "var(--color-project-card-go-to-enquiry-button-hover, #D03243)",

  /*Theme*/
  projectHeadingText: "var(--color-project-heading-text, #172C37)",
  backgroundPageProject: "var(--color-background-page-project, #FFFFFF)",
  sectionCtaInfoBackground: "var(--color-section-cta-info-background, #F1F0EE)",
  projectCardInfoText:
    "var(--color-project-card-info-text, rgba(23, 44, 55, 0.75))",
  projectCardDetailTitleText:
    "var(--color-project-card-detail-title-text, rgba(23, 44, 55, 0.25))",
  projectCardSitePlanText:
    "var(--color-project-card-site-plan-text, rgba(23, 44, 55, 1))",
  background: "var(--color-background, #F1F0EE)",
  color: "var(--color-color, rgba(23, 44, 55, 1))",
  error: "var(--color-error, #D03243)",
  transparent: "var(--color-transparent, rgba(0, 0, 0, 0))",
  backgroundImage: "var(--color-background-image, #F1F0EE)",
  success: "var(--color-success, #099628)",


  /* Table */
  tableText: "var(--color-table-text, #172C37)",
  tableHeadingText: "var(--color-table-heading-text, #FFFFFF)",
  tableHeadingBackground: "var(--color-table-heading-background, #172C37)",
  tableRowDivider: "var(--color-table-row-divider, rgba(0, 0, 0, 0.25))",

  // Project's menu
  projectMenuBorderBottom: "var(--color-project-menu-border-bottom, #172C3740)",

  // Curated properties
  curatedPropertiesHeading: "var(--color-curated-properties-heading, #172C37)",
  curatedPropertiesBackground:
    "var(--color-curated-properties-background, #F1F0EE)",

  // Filter bar mobile
  filterBarMobilePlaceholder:
    "var(--color-filter-bar-mobile-placeholder, rgba(23, 44, 55, 0.5))",
  filterBarMobileBackgroundColor:
    "var(--color-filter-bar-mobile-background-color, rgba(0, 0, 0, 0))",
  filterBarMobileText: "var(--color-filter-bar-mobile-text, #FFFFFF)",
  filterBarMobileButtonBackground:
    "var(--color-filter-bar-mobile-button-background,  #172C37)",
  filterBarMobileButtonText:
    "var(--color-filter-bar-mobile-button-text,  #FFFFFF)",

  // Card Feature
  cardFeatureBackground: "var(--color-card-feature-background, #FFFFFF)",
  cardFeatureText: "var(--color-card-feature-text, #172C37)",
  cardFeatureAddressText:
    "var(--color-card-feature-address, rgba(23, 44, 55, 0.5))",
  cardFeatureDivider: "var(--color-card-feature-divider, rgba(0, 0, 0, 1))",

  // Card Project
  cardProjectBackground: "var(--color-card-project-background, #FFFFFF)",
  cardProjectText: "var(--color-card-project-text, #172C37)",
  cardProjectAddressText:
    "var(--color-card-project-address, rgba(23, 44, 55, 0.5))",
  cardProjectDivider: "var(--color-card-project-divider, rgba(0, 0, 0, 1))",
  cardProjectTenure: "var(--color-card-project-tenure, #D03243)",
  cardProjectTenureText: "var(--color-card-project-tenure-text, #D03243)",

  // ModalContactUs
  contactUsText: "var(--color-contact-us-text, #172C37)",
  contactUsButtonText: "var(--color-contact-us-button-text, #FFFFFF)",
  contactUsButtonIcon: "var(--color-contact-us-button-icon, #099628)",
  showflatButtonIcon: "var(--color-showflat-button-background, rgba(9, 150, 40, 1))",
  showflatButtonBackground: "var(--color-showflat-button-background, rgba(9, 150, 40, 0.3))",
  contactUsButtonBackground:
    "var(--color-contact-us-button-background, #099628)",
  contactUsButtonBackgroundHover:
    "var(--color-contact-us-button-background-hover, #099628)",
  contactUsModalBackground: "var(--color-contact-us-modal-background, #FFFFFF)",
  contactUsModalBackdrop:
    "var(--color-contact-us-modal-backdrop, rgba(0, 0, 0, 0.5))",
  contactUsDropdownIcon: "var(--color-contact-us-dropdown-icon, #FFFFFF)",

  mastheadGalleryPaginationButton:
    "var(--color-masthead-gallery-pagination-button, #172C37)",

  // Card
  cardChipBackground:
    "var(--color-card-chip-background, rgba(23, 44, 55, 0.1))",
  cardChipText: "var(--color-card-chip-text, #172C37)",
  cardText: "var(--color-card-text, #172C37)",
  cardButtonText: "var(--color-card-button-text, rgba(23, 44, 55, 0.35))",
  cardButtonTextHover: "var(--color-card-button-text-hover, #172C37)",
  cardButtonActiveText: "var(--color-card-button-active-text, #FFFFFF)",
  cardUnitTypeHeading:
    "var(--color-card-unit-type-heading, rgba(23, 44, 55, 0.35))",
  cardSoldOutText: "var(--color-card-sold-out-text, #D03243)",

  black: "var(--color-black, #000000)",
  black15: "var(--color-black-15, rgba(0, 0, 0, 0.15))",
  black25: "var(--color-black-25, rgba(0, 0, 0, 0.25))",
  white: "var(--color-white, #FFFFFF)",
  white5: "var(--color-white-5, rgba(255, 255, 255, 0.05))",
  white60: "var(--color-white-60, rgba(255, 255, 255, 0.6))",
  white25: "var(--color-white-25, rgba(255, 255, 255, 0.25))",
  darkBlue: "var(--color-dark-blue, #2563EB)",
  cornflowerBlue: "var(--color-cornflower-blue, #F1F0EE)",
  brickRed: "var(--color-brick-red, #D03243)",
  gableGreen: "var(--color-gable-green, #172C37)",
  gableGreen5: "var(--color-gable-green-5, rgba(23, 44, 55, 0.05))",
  gableGreen10: "var(--color-gable-green-10, rgba(23, 44, 55, 0.1))",
  gableGreen25: "var(--color-gable-green-25, rgba(23, 44, 55, 0.25))",
  gableGreen35: "var(--color-gable-green-35, rgba(23, 44, 55, 0.35))",
  gableGreen50: "var(--color-gable-green-50, rgba(23, 44, 55, 0.5))",
  gableGreen75: "var(--color-gable-green-75, rgba(23, 44, 55, 0.75))",
  rustyRed: "var(--color-rusty-red, #D03243)",
  platinum: "var(--color-platinum, #E5E4E2)",
  darkGrey: "var(--color-dark-grey, rgba(217, 216, 214, 1))",
  darkGrey25: "var(--color-dark-grey-25, rgba(217, 216, 214, 0.25))",
  darkGrey40: "var(--color-dark-grey-40, rgba(217, 216, 214, 0.4))",
  philippineSilver: "var(--color-philippineSilver, #B5B4B2)",
  teal: "var(--color-teal, #007980)",
  mediumTurquoise: "var(--color-mediumTurquoise, #47CFD6)",
  successGreen: "var(--color-successGreen, #099628)",
  chineseSilver: "var(--color-chineseSilver, #CECDCB)",
  ufoGreen: "var(--color-ufoGreen, #41C351)",
  venetianRed: "var(--color-venetianRed, #CC1512)",
  lightSilver: "var(--color-lightSilver, #D9D9D9)",
  lightGreen: "var(--color-lightGreen, #099628)",
  cadetGrey: "var(--color-cadetGrey, #9CA3AF)",
};

export default colors;

