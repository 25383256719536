import { SCROLL_OFFSET } from "../constants/projectSections";

export default function scrollToView(targetId: string, offset: number = SCROLL_OFFSET) {
    // TODO: Optimize this function
    // Make sure all element wrapper tag name is section
    let sectionEl = document.getElementById(targetId);

    if (sectionEl && sectionEl?.tagName.toLocaleLowerCase() !== "section") {
        // For group  section ids, example: landing page table units
        sectionEl = sectionEl?.closest("section");
    }

    if (sectionEl) {
        const y =
            sectionEl.getBoundingClientRect().top + window.scrollY + offset;

        // Update the URL hash without scrolling again
        window.history.pushState(null, "", `#${targetId}`);
        // if (y === window.scrollY + offset) return; // return if window not change

        window.scrollTo({ top: y, behavior: "smooth" });
    }
}
