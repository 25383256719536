import { RefObject, useEffect, useState } from "react";

interface StickyObserverProps {
  ref: RefObject<HTMLElement>;
  root?: HTMLElement | null;
  threshold?: number;
  rootMargin?: string;
}

const useStickyObserver = ({
  ref,
  root = null,
  threshold = 0,
  rootMargin = "0px",
}: StickyObserverProps) => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const currentRef = ref.current; // Save the current ref value

      if (!currentRef) return; // Guard clause in case the ref is null

      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsSticky(entry.intersectionRatio < 1);
        },
        {
          root,
          threshold,
          rootMargin,
        }
      );

      observer.observe(currentRef);

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }
  }, [ref.current, root, threshold, rootMargin]);

  return isSticky;
};

export default useStickyObserver;
