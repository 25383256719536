import { useRef, useState } from "react";

import Image from "../atoms/Image";
import ImageResponsive from "../atoms/ImageResponsive";
import Video from "../atoms/Video";

import youtubePlay from "~/assets/images/youtube-play.png";
import { IMediaImageUrls, IMediaVideoUrls } from "~/entities/media";
import { cn } from "~/utilities/cn";

export interface LDPVideoProps {
  videoMedia?: IMediaVideoUrls;
  thumbnail?: IMediaImageUrls;
  className?: string;
}

export default function LDPVideo({
  videoMedia,
  thumbnail,
  className,
}: LDPVideoProps) {
  const ref = useRef<any>(null);
  const isHasVideo = !!videoMedia;

  const [isPlay, setIsPlay] = useState(false);

  const onPlay = () => {
    setIsPlay(true);
    ref.current?.play();
    // ref.current.currentTime = currentTime;
  };

  const onPause = () => {
    // setCurrentTime(ref?.current?.currentTime);

    ref.current?.pause();

    setIsPlay(false);
  };

  const onTriggerPlay = () => {
    if (!isHasVideo) return;
    !isPlay ? onPlay() : onPause();
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onTriggerPlay();
      }}
      className={cn(
        "ldp-video relative h-full w-full cursor-pointer",
        className
      )}
    >
      {thumbnail && !isPlay && (
        <ImageResponsive
          pictureClassName={cn(
            "w-full h-full block  z-10",
            isHasVideo && "absolute"
          )}
          className={cn(
            "video-thumbnail  object-over h-full w-full object-center"
          )}
          imageData={thumbnail}
          alt="thumbnail video"
        />
      )}
      {videoMedia && (
        <Video
          videoData={videoMedia}
          videoProps={{
            ref,
            onEnded: () => {
              setIsPlay(false);
            },
            loop: false,
            muted: true,
          }}
          className="video aspect-h-[auto] aspect-w-[auto] h-full lg:aspect-h-9 lg:aspect-w-16 lg:h-auto lg:w-full"
          controls={isPlay}
        />
      )}

      {!isPlay && videoMedia && (
        <Image
          className="absolute left-1/2 top-1/2 z-50 h-auto w-[100px] -translate-x-1/2 -translate-y-1/2 object-contain sm:w-9"
          isLocalImage
          url={youtubePlay}
        />
      )}
    </div>
  );
}
