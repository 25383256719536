import { useFetcher } from "@remix-run/react";
import React, { Suspense, useEffect, useRef } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { toast } from "sonner";

import { Icon } from "../Icons";

import { TMedia } from "~/entities/media";
import { EIcon } from "~/utilities/enums/Icons";
import { ActionSlug } from "~/utilities/enums/Slug";

export interface FileUploaderProps {
  onUpload: (files: TMedia[]) => void;
  accept?: Accept;
  multiple?: boolean;
  dragAndDrop?: boolean;
  DropZoneContent?: (props?: { uploading?: boolean }) => React.ReactNode;
}

const FileUploader: React.FC<FileUploaderProps> = ({
  onUpload,
  accept,
  multiple = true,
  dragAndDrop = true,
  DropZoneContent,
}) => {
  const fetcher = useFetcher<any>();
  const uploadingIdRef = useRef<any>();

  const uploadFileAction = (data: { files: File[] }) => {
    const formData = new FormData();

    data.files.forEach((file, index) => {
      formData.append(`files[${index}]`, file);
    });

    uploadingIdRef.current = toast.loading("Uploading. Please wait...");
    const response = fetcher.submit(formData, {
      method: "POST",
      action: `${ActionSlug.UPLOAD_FILE}`,
      encType: "multipart/form-data",
    });

    return response;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        return uploadFileAction({ files: acceptedFiles });

        // onUpload(response.data);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    },

    onFileDialogOpen: () => {
      const main = document.getElementsByTagName("body");
    },

    accept,
    multiple,
    noClick: !dragAndDrop,
  });

  useEffect(() => {
    if (fetcher?.data && fetcher?.data?.result === "success") {
      toast.success(fetcher.data?.message || "Updated Successfully!", {
        id: uploadingIdRef?.current,
      });

      const fileUploaded = fetcher?.data?.data?.map(
        (media: TMedia & { mediaUploaded?: boolean }) => ({
          ...media,
          mediaUploaded: true,
        })
      );

      onUpload?.(fileUploaded);
    } else if (
      !(fetcher.state === "idle") &&
      fetcher.data?.result === "failed"
    ) {
      toast.error(
        fetcher.data?.message
          ? `[Upload File Failed]: ${fetcher.data.message}`
          : "Update Failed!",
        { id: uploadingIdRef?.current }
      );
    }
  }, [fetcher?.data]);

  return (
    <Suspense>
      <div
        {...getRootProps()}
        className={`flex cursor-pointer flex-col items-center justify-center rounded-xl border-2 border-dashed p-6 transition 
        ${
          isDragActive
            ? "border-blue-500 bg-blue-100"
            : "border-gray-300 bg-gray-50"
        }
      `}
      >
        <input {...getInputProps()} />
        <Icon TypeIcon={EIcon.PlusIcon} className="h-12 w-12 text-gray-500" />
        <p className="mt-2 text-sm text-gray-700">
          {isDragActive
            ? "Drop the files here..."
            : "Drag & drop some files here, or click to select files"}
        </p>
      </div>
    </Suspense>
  );
};

FileUploader.displayName = "FileUploader";
export default FileUploader;
