import { Config as PuckConfig, FieldLabel } from "@measured/puck";
import { useMemo } from "react";

import {
  ESectionLDP,
  GROUP_COLORS_ROOT,
  GROUP_COLORS_SECTIONS_O2,
  VARIABLE_COLORS_ROOT,
  VARIABLE_COLORS_SECTIONS_O2,
} from "../landingPageConfig";
import projectSectionConfig from "../projectSectionConfig";
import { IPuckConfigData, usePuckContext } from "../puckConfig";
import { EMedia } from "../../enums/Media";

import QuillEditor from "~/components/atoms/QuillRenderHtml/QuillEditor.client";
import LDPContactSaleForm from "~/components/LDP/LDPConTactSaleForm";
import LDPHeader from "~/components/LDP/LDPHeader";
import LDPPartners from "~/components/LDP/LDPPartners";
import LDPSection from "~/components/LDP/LDPSection";
import LDPSitePlan from "~/components/LDP/LDPSitePlan";
import LDPUnits from "~/components/LDP/LDPUnits";
import LDPContactSales from "~/components/LDP/options2/LDPContactSales";
import LDPGallery from "~/components/LDP/options2/LDPGallery";
import LDPLatestUpdate from "~/components/LDP/options2/LDPLatestUpdate";
import LDPMarketing from "~/components/LDP/options2/LDPMakerting";
import LDPProjectDetail from "~/components/LDP/options2/LDPProjectDetail";
import LDPProjectVideo from "~/components/LDP/options2/LDPProjectVideo";
import ColorPickerGroup from "~/components/molecules/ColorPickerGroup";
import ContactSalesSticky from "~/components/organisms/ContactSalesSticky";
import FooterLDP from "~/components/organisms/FooterLDP";
import MastheadGalleryLDP from "~/components/organisms/MastheadGalleryLDP";
import UploaderModal from "~/components/organisms/UploaderModal/index.client";
import SectionProjectLocationMapLDP from "~/components/sections/SectionProjectLocationMapLDP";
import SectionProjectVirtualTourLDP from "~/components/sections/SectionProjectVirtualTourLDP";
import { ELDPOption } from "~/types/landingPage";
import { latestUpdateContentString } from "~/utilities/constants/defaultContent";
import { Section } from "~/utilities/enums/ProjectSections";
import getRandomItem from "~/utilities/helpers/randomItem";
import scrollToView from "~/utilities/helpers/scrollToView";

const PuckConfigO2 = ({
  project,
  pageConfig,
  validProjectMenuItems,
  floorPlansPagination,
  unitTransactions,
  virtualTours,
  saleTransactionFilterOptions,
  floorPlanFilterOptions,
  imageSources,
  token,
  i18nT,
  editorMode,
}: IPuckConfigData): PuckConfig => {
  const agencyLogo = pageConfig?.user?.agencyLogo;
  const projectDesktopLogo = project.desktopLogo;
  const projectMobileLogo = project.mobileLogo;
  const salesTeamInfo = pageConfig?.salesTeamInfo;

  return {
    components: {
      [ESectionLDP.LDPMastheadGallery]: {
        label: "Masthead Gallery",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={
                      GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPMastheadGallery]
                    }
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },

          titleHtml: {
            type: "custom",
            label: "Title",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Title"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },
          subTitleHtml: {
            type: "custom",
            label: "Sub title",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Sub title"
                onChange={onChange}
                value={value}
                defaultValue={project?.location?.name}
              />
            ),
          },

          galleryMedias: {
            label: "Gallery Medias",
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Thumbnail">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                  />
                </FieldLabel>
              );
            },
          },
        },
        resolveData: async ({ props }, { changed }) => {
          return props;
        },
        defaultProps: {
          titleHtml: project?.name,
          subTitleHtml: project?.location?.name,
          galleryMedias: project?.medias || [],
          styleRoot:
            VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPMastheadGallery],
        },
        render: ({
          styleRoot,
          titleHtml,
          subTitleHtml,
          galleryMedias,
        }: any) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks

          return (
            <MastheadGalleryLDP
              project={project}
              onShowPriceList={() => {}}
              onShowFlat={() => {}}
              showGradientLayer
              styleRoot={styleRoot}
              titleHtml={titleHtml}
              subTitleHtml={subTitleHtml}
              galleryMedias={galleryMedias}
            />
          );
        },
      },
      [ESectionLDP.LDPLatestUpdate]: {
        label: "Latest Update",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={
                      GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPLatestUpdate]
                    }
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
          titleHtml: {
            type: "custom",
            label: "Title",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Title"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },
          descriptionHtml: {
            type: "custom",
            label: "Content",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Content"
                onChange={onChange}
                value={value}
                defaultValue={project?.location?.name}
              />
            ),
          },
          image: {
            label: "Image",
            type: "custom",
            render: ({ value, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Image">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    maxSelect={1}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
        },
        defaultProps: {
          titleHtml: `Latest Update`,
          descriptionHtml: latestUpdateContentString,
          image: [getRandomItem(imageSources)],
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPLatestUpdate],
        },
        render: ({ titleHtml, styleRoot, descriptionHtml, image }: any) => {
          return (
            <LDPLatestUpdate
              titleHtml={titleHtml}
              descriptionHtml={descriptionHtml}
              image={image?.[0]}
              styleRoot={styleRoot}
            />
          );
        },
      },
      [ESectionLDP.LDPProjectDetail]: {
        label: "Project Detail",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={
                      GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPProjectDetail]
                    }
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
          title: {
            type: "custom",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Title"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },
          description: {
            type: "custom",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Description"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },

          image: {
            label: "Image",
            type: "custom",
            render: ({ value, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Image">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    maxSelect={1}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
        },
        defaultProps: {
          title: project?.name || "Project Detail",
          image: [getRandomItem(imageSources)],
          description: project?.description || "",
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPProjectDetail],
        },
        render: ({ title, styleRoot, image, description }: any) => {
          return (
            <LDPProjectDetail
              image={image?.[0]}
              id={Section.PROJECT_DETAILS}
              title={title}
              project={project}
              styleRoot={styleRoot}
              description={description}
            />
          );
        },
      },

      [ESectionLDP.LDPProjectVideo]: {
        label: "Project Video",
        fields: {
          title: {
            type: "custom",
            label: "Title",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Title"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },

          descriptionHtml: {
            type: "custom",
            label: "Quote",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Content"
                onChange={onChange}
                value={value}
                defaultValue={project?.location?.name}
              />
            ),
          },
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={
                      GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPProjectVideo]
                    }
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },

          partnerLogo: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const defaultMedia = [...(value || [])];

              return (
                <FieldLabel label="Developer Logo">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={defaultMedia}
                    maxSelect={1}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
          thumbnail: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Thumbnail Video">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    maxSelect={1}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },

          videoMedia: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Video">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    maxSelect={1}
                    accept={
                      {
                        // "video/*": ["*"],
                      }
                    }
                    allowYoutube={true}
                  />
                </FieldLabel>
              );
            },
          },
        },
        defaultProps: {
          title: "Video",
          descriptionHtml: "Description Video",
          thumbnail: [getRandomItem(imageSources)],
          videoMedia: [
            getRandomItem(
              project?.medias?.filter((item) => item.type === EMedia.VIDEO)
            ),
          ],
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPProjectVideo],
        },
        render: ({
          title,
          descriptionHtml,
          styleRoot,
          thumbnail,
          videoMedia,
          partnerLogo,
        }) => {
          return (
            <LDPProjectVideo
              title={title}
              descriptionHtml={descriptionHtml}
              videoMedia={videoMedia?.[0]}
              thumbnail={thumbnail?.[0]}
              partnerLogo={partnerLogo?.[0]?.urls?.[0]?.url}
              styleRoot={styleRoot}
            />
          );
        },
      },

      [ESectionLDP.LDPPartners]: {
        label: "Partners",
        fields: {
          hasBorder: {
            type: "radio",
            label: "Border",
            options: [
              { label: "On", value: true },
              { label: "Off", value: false },
            ],
          },

          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPPartners]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
        },
        defaultProps: {
          hasBorder: true,
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPPartners],
        },
        render: ({ hasBorder, styleRoot }) => {
          return <LDPPartners hasBorder={hasBorder} styleRoot={styleRoot} />;
        },
      },

      [ESectionLDP.LDPMarketing]: {
        label: "Marketing",
        fields: {
          title: {
            type: "custom",
            label: "Title",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Title"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },

          descriptionHtml: {
            type: "custom",
            label: "Description",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Content"
                onChange={onChange}
                value={value}
                defaultValue={project?.location?.name}
              />
            ),
          },

          highlightThumbnail: {
            label: "Highlight Image",
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Highlight Image">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    maxSelect={1}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
          imagesData: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Images">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d || []);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPMarketing]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
        },

        defaultProps: {
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPMarketing],
          imagesData: imageSources,
          title: "Your Haven Amongst the Bustling City",
          descriptionHtml:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        },
        render: ({
          styleRoot,
          title,
          descriptionHtml,
          imagesData,
          highlightThumbnail,
        }) => {
          return (
            <LDPMarketing
              styleRoot={styleRoot}
              imagesData={imagesData}
              descriptionHtml={descriptionHtml}
              title={title}
              // highlightThumbnail={highlightThumbnail?.[0]}
            />
          );
        },
      },

      [ESectionLDP.LDPUnits]: {
        label: "Units",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPUnits]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
        },

        defaultProps: {
          styleRoot: {
            ...VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPUnits],
          },
        },

        render: ({ styleRoot, title, descriptionHtml, imagesData }) => {
          return (
            <LDPUnits
              project={project}
              floorPlansPagination={floorPlansPagination}
              floorPlanFilterOptions={floorPlanFilterOptions}
              unitTransactions={unitTransactions}
              saleTransactionFilterOptions={saleTransactionFilterOptions}
              styleRoot={styleRoot}
            />
          );
        },
      },

      [ESectionLDP.LDPLocation]: {
        label: "Location",
        fields: {
          // title: { type: "text", label: "Title" },
          // description: { type: "textarea", label: "Description" },
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPLocation]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
        },
        permissions: {
          insert: !!project?.amenityHtml || !!project?.googleMapUrl,
        },

        defaultProps: {
          styleRoot: {
            ...VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPLocation],
          },
          title: i18nT(projectSectionConfig[Section.LOCATION_MAP].title),
          description: i18nT(
            projectSectionConfig[Section.LOCATION_MAP].description
          ),
          id: Section.LOCATION_MAP,
        },
        render: ({ styleRoot, title, description, id }) => {
          if (!project?.amenityHtml && !project?.googleMapUrl) {
            return <></>;
          }
          return (
            <LDPSection styleRoot={styleRoot}>
              <SectionProjectLocationMapLDP
                id={id}
                title={title}
                description={description}
                project={project}
                className="py-20 lg:py-12 [&_*]:!text-[var(--color-text)]"
              />
            </LDPSection>
          );
        },
      },

      [ESectionLDP.LDPContactSales]: {
        label: "Contact Sales",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={
                      GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPContactSales]
                    }
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
          contactSaleImage: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Background image">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    maxSelect={1}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
        },

        defaultProps: {
          styleRoot: {
            ...VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPContactSales],
            "--color-color": `var(--color-enquiry-text)`,
          },

          id: Section.CONTACT_SALES,
        },

        render: ({ styleRoot, id, contactSaleImage }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const image = useMemo(() => {
            return contactSaleImage?.[0] || getRandomItem(imageSources);
          }, [contactSaleImage]);
          return (
            <LDPContactSales
              contactSaleImage={image}
              id={id}
              styleRoot={styleRoot}
            />
          );
        },
      },

      [ESectionLDP.LDPVirtualTour]: {
        label: "Virtual Tour",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPVirtualTour]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
        },
        permissions: {
          insert: !!virtualTours?.length,
        },

        defaultProps: {
          styleRoot: {
            ...VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPVirtualTour],
            "--color-project-heading-text": "var(--color-text)",
            "--color-color": "var(--color-text)",
          },

          id: Section.VIRTUAL_TOUR,
          title: i18nT(projectSectionConfig[Section.VIRTUAL_TOUR].title),
          description: i18nT(
            projectSectionConfig[Section.VIRTUAL_TOUR].description
          ),
        },

        render: ({ styleRoot, id, title, description }) => {
          if (!virtualTours || virtualTours?.length === 0) return <></>;

          return (
            <LDPSection styleRoot={styleRoot} className="max-w-full px-0">
              <SectionProjectVirtualTourLDP
                // ref={(el) =>
                //   (sectionRefs.current[SectionIndex.VIRTUAL_TOUR] = el!)
                // }
                id={id}
                title={title}
                description={description}
                virtualTours={virtualTours}
                project={project}
                unitTypeOptions={project?.unitTypes?.map((unitType) => ({
                  id: unitType?.id,
                  name: unitType?.title,
                }))}
              />
            </LDPSection>
          );
        },
      },

      [ESectionLDP.LDPSitePlan]: {
        label: "Site Plan",
        fields: {
          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPSitePlan]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },
          sitePlanImage: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.siteplanImages || []),
              ];

              return (
                <FieldLabel label="Site Plan Image">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || []}
                    projectMedias={projectMediasDefault}
                    accept={{
                      "image/*": ["*"],
                    }}
                  />
                </FieldLabel>
              );
            },
          },
        },
        permissions: {
          insert: !!project?.facilityHtml,
        },

        defaultProps: {
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPSitePlan],
          id: Section.SITE_PLAN,
          title: i18nT(projectSectionConfig[Section.SITE_PLAN].title),
          description: i18nT(
            projectSectionConfig[Section.SITE_PLAN].description
          ),
          sitePlanImage:
            project?.siteplanImages?.length > 0
              ? project?.siteplanImages
              : project?.medias?.filter((item) => item.type === EMedia.IMAGE) ||
                [],
        },

        render: ({ styleRoot, id, title, description, sitePlanImage }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return project?.facilityHtml ? (
            <LDPSitePlan
              id={id}
              title={title}
              description={description}
              className="ldp"
              sitePlanHtml={project?.facilityHtml || ""}
              sitePlanImages={sitePlanImage}
              styleRoot={styleRoot}
              hasBackgroundImg
            />
          ) : (
            <></>
          );
        },
      },

      [ESectionLDP.LDPGallery]: {
        label: "Gallery",
        fields: {
          title: {
            type: "custom",
            label: "Title",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Title"
                onChange={onChange}
                value={value}
                defaultValue={project?.name}
              />
            ),
          },

          description: {
            type: "custom",
            label: "Description",
            render: ({ onChange, value }) => (
              <QuillEditor
                label="Content"
                onChange={onChange}
                value={value}
                defaultValue={project?.location?.name}
              />
            ),
          },

          styleRoot: {
            label: "Colors",
            type: "custom",
            render: ({ value, name, onChange }) => {
              return (
                <FieldLabel label="Colors">
                  <ColorPickerGroup
                    group={GROUP_COLORS_SECTIONS_O2[ESectionLDP.LDPGallery]}
                    onChange={onChange}
                    value={value}
                  />
                </FieldLabel>
              );
            },
          },

          medias: {
            type: "custom",
            render: ({ value, name, onChange }) => {
              const projectMediasDefault = [
                ...(value || []),
                ...(project?.medias || []),
              ];

              return (
                <FieldLabel label="Gallery Images">
                  <UploaderModal
                    onChange={(d) => {
                      onChange(d);
                    }}
                    value={value || project?.medias || []}
                    projectMedias={projectMediasDefault}
                    accept={{
                      "image/*": ["*"],
                      // "video/*": ["*"],
                    }}
                    allowYoutube={true}
                  />
                </FieldLabel>
              );
            },
          },
        },
        permissions: {
          insert: project?.medias?.length > 0,
        },

        defaultProps: {
          styleRoot: VARIABLE_COLORS_SECTIONS_O2[ESectionLDP.LDPGallery],
          id: Section.GALLERY,
          title: i18nT(projectSectionConfig[Section.GALLERY].title),
          description: "Discover more images about the project",
          medias: project?.medias || [],
        },

        render: ({ styleRoot, id, title, description, medias }) => {
          return project?.medias?.length > 0 ? (
            <LDPGallery
              id={id}
              title={title}
              project={project}
              description={description}
              styleRoot={styleRoot}
              medias={medias}
            />
          ) : (
            <></>
          );
        },
      },
    },

    root: {
      fields: {
        styleRoot: {
          label: "Global Colors",
          type: "custom",
          render: ({ value, name, onChange }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { action } = usePuckContext();
            return (
              <FieldLabel label="Global Colors">
                <ColorPickerGroup
                  group={GROUP_COLORS_ROOT[ELDPOption.O2]}
                  onChange={(value) => {
                    onChange(value);
                    action.setColorVariables(value);
                  }}
                  value={value}
                />
              </FieldLabel>
            );
          },
        },

        footerStyles: {
          label: "Footer Colors",
          type: "custom",
          render: ({ value, name, onChange }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { action } = usePuckContext();
            return (
              <FieldLabel label="Footer Colors">
                <ColorPickerGroup
                  group={GROUP_COLORS_ROOT.FOOTER}
                  onChange={(value) => {
                    onChange(value);
                    action.setColorVariables(value);
                  }}
                  value={value}
                />
              </FieldLabel>
            );
          },
        },
        logos: {
          label: "Landing page logo",
          type: "object",
          objectFields: {
            desktopLogo: {
              type: "custom",
              render: ({ value, name, onChange }) => {
                return (
                  <FieldLabel label="Desktop logo">
                    <UploaderModal
                      onChange={(d) => {
                        onChange(d);
                      }}
                      value={value || []}
                      projectMedias={value || []}
                      maxSelect={1}
                      accept={{
                        "image/*": ["*"],
                      }}
                    />
                  </FieldLabel>
                );
              },
            },

            mobileLogo: {
              type: "custom",
              render: ({ value, name, onChange }) => {
                const defaultMedias = [...(value || []), projectMobileLogo];
                return (
                  <FieldLabel label="Mobile logo">
                    <UploaderModal
                      onChange={(d) => {
                        onChange(d);
                      }}
                      value={value || []}
                      projectMedias={defaultMedias}
                      maxSelect={1}
                      accept={{
                        "image/*": ["*"],
                      }}
                    />
                  </FieldLabel>
                );
              },
            },

            agencyLogo: {
              type: "custom",
              render: ({ value, name, onChange }) => {
                return (
                  <FieldLabel label="Agency logo">
                    <UploaderModal
                      onChange={(d) => {
                        onChange(d);
                      }}
                      value={value || []}
                      projectMedias={value || []}
                      maxSelect={1}
                      accept={{
                        "image/*": ["*"],
                      }}
                    />
                  </FieldLabel>
                );
              },
            },
          },
        },
        // saleTeamInfo: {
        //   label: "Sale team Info",
        //   type: "object",
        //   objectFields: {
        //     phoneNumber: { type: "text", label: "Contact Phone Number" },
        //     saleTeamName: { type: "text", label: "Agent Name" },
        //     registrationNumber: { type: "text", label: "CEA Reg No" },
        //     currentEa: { type: "text", label: "Agency Name" },
        //     licenseNumber: { type: "text", label: "Agency License No" },
        //   },
        // },

        hasFormSale: {
          type: "radio",
          label: "On/off form enquiry",
          options: [
            { label: "On", value: true },
            { label: "Off", value: false },
          ],
        },
      },
      defaultProps: {
        styleRoot: VARIABLE_COLORS_ROOT[ELDPOption.O2],
        saleTeamInfo: {
          phoneNumber: pageConfig?.phoneNumber || pageConfig?.user?.phone,
          saleTeamName: salesTeamInfo?.name || "",
          registrationNumber: salesTeamInfo?.registrationNumber || "",
          currentEa: salesTeamInfo?.currentEa || "",
          licenseNumber: salesTeamInfo?.licenseNumber || "",
        },
        hasFormSale: true,
        footerStyles: VARIABLE_COLORS_ROOT.FOOTER,
        logos: {
          mobileLogo: projectMobileLogo && [projectMobileLogo],
          desktopLogo: projectDesktopLogo && [projectDesktopLogo],
          agencyLogo: agencyLogo && [agencyLogo],
        },
      },
      render: ({
        children,
        styleRoot,
        saleTeamInfo,
        hasFormSale,
        logos,
        footerStyles,
      }: any) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { action } = usePuckContext();
        action.setColorVariables(styleRoot);

        return (
          <main
            style={{
              ...styleRoot,
              "--color-color": styleRoot?.["--color-dark"],
            }}
          >
            <div className="remove-padding flex min-h-screen w-full flex-col items-center overflow-hidden bg-[var(--bg-page)]">
              <LDPHeader
                logo={logos?.desktopLogo?.[0]}
                logoMobile={logos?.mobileLogo?.[0]}
                project={project}
                topButton={pageConfig.topButton}
                sections={validProjectMenuItems}
                validProjectMenuItems={validProjectMenuItems}
                projectMenuItems={validProjectMenuItems}
                defaultSection={validProjectMenuItems?.[0]}
                phoneNumber={pageConfig?.phoneNumber || ""}
                socialLinks={pageConfig?.socialLinks || []}
                top={0}
                editorMode={editorMode}
              />
              <div className="w-full">{children}</div>
            </div>
            <FooterLDP
              styles={footerStyles}
              sections={validProjectMenuItems}
              logo={logos?.desktopLogo?.[0]}
              phoneNumber={saleTeamInfo?.phoneNumber || ""}
              socialLinks={pageConfig?.socialLinks || []}
              siteTitle={pageConfig?.siteTitle || ""}
              siteDescription={
                (pageConfig?.siteDescription as unknown as string) || ""
              }
              disclaimer={pageConfig?.siteDisclaimers?.footerDisclaimer}
              wrapperClass="ldp"
              extraComponent={
                hasFormSale && (
                  <LDPContactSaleForm className="rounded-3xl bg-white lg:rounded-xl" />
                )
              }
              saleTeamInfo={{
                currentEa: saleTeamInfo?.currentEa,
                licenseNumber: saleTeamInfo?.licenseNumber,
                registrationNumber: saleTeamInfo?.registrationNumber,
                saleTeamName: saleTeamInfo?.saleTeamName,
                agencyLogoCustomize: logos?.agencyLogo?.[0],
              }}
              editorMode={editorMode}
            />

            <ContactSalesSticky
              salePhoneNumber={
                saleTeamInfo?.phoneNumber || pageConfig?.user?.phone || ""
              }
              saleContent={`Hi, I am interested to view ${
                project?.name || ""
              } sales gallery. Thank you`}
              onRegisterClick={() => {
                scrollToView(Section.CONTACT_SALES);
              }}
              className={editorMode ? "pointer-events-none select-none" : ""}
            />
          </main>
        );
      },
    },
  };
};

export default PuckConfigO2;
