
export default function getRandomItem(array: any[]) {
    if (!array?.length) {
        return [];
    }
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
}


