import { toast } from "sonner";

import { Icon } from "../Icons";
import Media from "../Media";
import VideoYouTube from "../VideoYouTube";

import { TMedia } from "~/entities/media";
import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";
import { EMedia } from "~/utilities/enums/Media";

interface PreviewMediaProps {
  id: string;
  media: TMedia;
  isDefault?: boolean;
  onSelect?: (media: TMedia, selected: boolean) => void;
  position?: number;
  isMaxSelected?: boolean;
  isMultiple?: boolean;
  hoveringId?: string | null;
  onHover?: (id: string) => void;
  onLeave?: () => void;
}

export default function PreviewMedia({
  id,
  media,
  isDefault = false,
  onSelect,
  position,
  isMaxSelected,
  hoveringId,
  onHover,
  onLeave,
  isMultiple = true,
}: PreviewMediaProps) {
  const selected = position && position > 0;

  const handleSelect = () => {
    const isDisableAdd = isMaxSelected && !selected && isMultiple;

    if (isDisableAdd) {
      toast.error(`Selection limit exceeded`);
      return;
    }

    const newSelected = !selected;
    onSelect?.(media, newSelected);
  };

  const isYouTubeVideo =
    media.type === EMedia.VIDEO &&
    media.urls.length > 0 &&
    (media.urls[0].includes("youtube.com") ||
      media.urls[0].includes("youtu.be"));

  return (
    <div
      data-preview-id={id}
      className={cn(
        "group relative aspect-[16/9] h-auto w-full cursor-pointer overflow-hidden rounded-lg border transition-all",
        selected ? "border-blue-500" : "border-gray-300",
        "hover:bg-gray-100",
        hoveringId === id && "border-2 border-amber-500 shadow-inner"
      )}
      onClick={handleSelect}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleSelect();
        }
      }}
      role="button"
      tabIndex={-1}
      onMouseEnter={() => {
        onHover?.(id);
      }}
      onMouseLeave={() => {
        onLeave?.();
      }}
    >
      <div
        className={cn(
          "absolute left-2 top-2 z-10 rounded-md px-2 py-1 text-xs font-semibold text-white",
          isDefault ? "bg-gray-600" : "bg-green-500"
        )}
      >
        {isDefault ? "Project" : isYouTubeVideo ? "YouTube" : "Upload"}
      </div>

      <div className="flex h-full w-full items-center justify-center overflow-hidden rounded-md bg-gray-200">
        {isYouTubeVideo ? (
          <div className="h-full w-full">
            <VideoYouTube videoData={media} className="h-full w-full" />
          </div>
        ) : (
          <Media
            media={media}
            className="h-full w-full object-cover"
            pictureClassName="block"
          />
        )}
      </div>

      <div
        className={cn(
          "absolute right-2 top-2 flex size-5 items-center justify-center rounded-full transition-all",
          selected ? "bg-blue-500 text-white" : "bg-gray-300 text-gray-700"
        )}
      >
        {selected ? (
          <Icon TypeIcon={EIcon.CheckedIcon} className="size-3" />
        ) : (
          <div className="h-3 w-3 rounded-full border border-gray-700"></div>
        )}
      </div>

      {selected && (
        <div className="absolute bottom-2 right-2 rounded-md bg-blue-500 px-2 py-1 text-xs font-semibold text-white">
          #{position}
        </div>
      )}
    </div>
  );
}
