import LDPSection, { LDPSectionProps } from "../../LDPSection";

import CleanedContent from "~/components/atoms/CleanedContent";
import ImageResponsive from "~/components/atoms/ImageResponsive";
import { IMediaImageUrls } from "~/entities/media";
import { latestUpdateContentString } from "~/utilities/constants/defaultContent";

export interface LDPLatestUpdateProps extends LDPSectionProps {
  titleHtml?: string;
  descriptionHtml?: string;
  image: IMediaImageUrls;
}

export default function LDPLatestUpdate({
  styleRoot = {
    "--bg-section": "var(--bg-page)",
    "--bg-wrapper": "#D2DFD5",
    "--main-color": "var(--color-color)",
  },
  titleHtml,
  descriptionHtml = latestUpdateContentString,
  image,
}: LDPLatestUpdateProps) {
  return (
    <LDPSection className="py-[98px] lg:py-12" styleRoot={styleRoot}>
      <div className="flex  flex-row gap-[130px] lg:flex-col-reverse lg:gap-8">
        <div className="flex flex-1  flex-col gap-y-12 lg:gap-y-8">
          <div>
            <CleanedContent
              className="text-header-48 font-bold !text-[var(--main-color)] lg:w-full lg:!text-[28px]/[34px]"
              htmlContent={titleHtml || `Latest Update`}
            />
          </div>

          {descriptionHtml && (
            <div className="mr-5">
              <CleanedContent
                className="!text-[var(--main-color)] lg:w-full"
                htmlContent={descriptionHtml || ""}
              />
            </div>
          )}
        </div>

        <div className="w-[506px] lg:w-full">
          <ImageResponsive
            className="h-[304px] w-[506px] overflow-hidden rounded-br-[160px] object-cover object-center lg:aspect-[343/220] lg:h-auto  lg:w-full lg:rounded-br-[120px] "
            pictureClassName="block"
            imageData={image}
          />
        </div>
      </div>
    </LDPSection>
  );
}
